import Joi from "joi";
import { sharedCreateThingsSchema } from "./sharedValidators";

export const sharedClientCreateSchema = sharedCreateThingsSchema
  .append({
    firstName: Joi.string().optional().empty(""),
    lastName: Joi.string().optional().empty(""),
    email: Joi.string()
      .email({
        tlds: { allow: false },
      })
      .optional()
      .empty(""),
    phone: Joi.string().optional().empty(""),
    mblsClientId: Joi.string().optional(),
    notes: Joi.string().optional().empty(""),
    tenantId: Joi.string().required(),
    language: Joi.string().optional(),
  })
  .or("email", "phone")
  .messages({
    "object.missing": "Please provide an email or a phone number",
  });

export const sharedValidateClientToCreate = (data: any) => {
  return sharedClientCreateSchema.validate(data);
};
