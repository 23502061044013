import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { t } from "i18next"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

const paginationPageSize = 100
const defaultPaginationModel = {
  pageSize: paginationPageSize,
  page: 0,
}

type Sl2DataGridProps = {
  isLoading?: boolean
  datagridProps: Partial<DataGridProps>
  onNextTokenChange: (nextToken: string | undefined) => void
  nextToken: string | undefined
  listCount: number
}
export const Sl2DataGrid: FC<Sl2DataGridProps> = ({
  datagridProps,
  isLoading,
  onNextTokenChange,
  nextToken,
  listCount,
}) => {
  const [maxFetchedPages, setMaxFetchedPages] = useState<number>(0)

  const [paginationModel, setPaginationModel] = useState<any>(
    defaultPaginationModel,
  )

  useEffect(() => {
    if (listCount < paginationPageSize) {
      setPaginationModel(defaultPaginationModel)
      setMaxFetchedPages(0)
    }
  }, [listCount])

  const onPaginationModelChange = useCallback(
    (newPaginationModel: any) => {
      setPaginationModel(newPaginationModel)

      if (newPaginationModel.page > maxFetchedPages) {
        const nextTokenString =
          typeof nextToken === "string" ? nextToken : JSON.stringify(nextToken)
        onNextTokenChange(nextTokenString)
      }

      setMaxFetchedPages(Math.max(maxFetchedPages, newPaginationModel.page))
    },
    [maxFetchedPages, nextToken, onNextTokenChange],
  )

  const rowCount = useMemo(() => {
    return nextToken ? Number.MAX_VALUE : listCount
  }, [listCount, nextToken])

  return (
    // @ts-ignore
    <DataGrid
      {...datagridProps}
      loading={isLoading}
      localeText={{
        noRowsLabel: isLoading ? "Loading ..." : t("noData"),
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} of ${count === Number.MAX_VALUE ? "many" : count}`,
        },
        ...datagridProps?.localeText,
      }}
      autoHeight
      rowSelection={false}
      sx={{
        // disable cell selection style
        ".MuiDataGrid-cell:focus": {
          outline: "none",
        },
        // pointer cursor on ALL rows
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
      }}
      rowCount={rowCount}
      paginationMode="client"
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
    />
  )
}

export default Sl2DataGrid
