import { Refresh } from "@mui/icons-material"
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { ReportDataset } from "shared/report-datasets"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import RectangularChip from "./RectangularChip"

const defaultColWidth = 150

const columns = (t: TFunction, tenantId: string): DataGridProps["columns"] => [
  // reservation.sstReservation.number

  {
    field: "payment_method",
    headerName: t("paymentMethod"),
    width: defaultColWidth,
    renderCell: (params) => {
      const paymentMethod = params.value || "unknown"
      return (
        <RectangularChip
          label={t(`${paymentMethod}`)}
          size="small"
          variant="outlined"
        />
      )
    },
  },

  {
    field: "total_collected",
    headerName: t("total_collected"),
    width: defaultColWidth,
    renderCell: (params) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.value)
    },
  },
  {
    field: "nb_reservations",
    headerName: t("nb_reservations"),
    width: defaultColWidth,
  },
]
type OsReportingPaymentMethodSummaryProps = {
  ds?: ReportDataset<any>
  isLoading: boolean
  refresh: () => void
  hideHeader?: boolean
  hideRefresh?: boolean
  width?: number
}
export const OsReportingPaymentMethodSummary: FC<
  OsReportingPaymentMethodSummaryProps
> = ({ ds, isLoading, refresh, hideHeader, hideRefresh, width = 300 }) => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const isReady = useAppSelector(selectMeIsReady)

  return (
    <>
      {!isReady && (
        <>
          <Stack direction="column" spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="100%" height={118 / 2} />
          </Stack>
        </>
      )}
      {isReady && tenantId && (
        <Box
          sx={{
            width: "auto",
            display: "inline-block",
          }}
        >
          {/* stack row with refresh btn */}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">{t("paymentMethodSummary")}</Typography>
            <IconButton onClick={refresh} disabled={isLoading}>
              <Refresh />
            </IconButton>
          </Stack>
          <DataGrid
            columns={columns(t, tenantId)}
            rows={ds?.data || []}
            localeText={{
              noRowsLabel: isLoading ? "Loading ..." : t("noData"),
            }}
            autoHeight
            getRowId={(row) => row.payment_method || ""}
            loading={isLoading}
            rowSelection={false}
            sx={{
              // disable cell selection style
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
              // pointer cursor on ALL rows
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
          />
        </Box>
      )}
    </>
  )
}

export default OsReportingPaymentMethodSummary
