import { FC, useEffect } from "react"
import "./App.css"
import PublicHomePage from "./components/PublicHomePage"

import "react-json-view-lite/dist/index.css"
import { useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { useAppAuth } from "./auth/AuthProvider"
import { AuthLoadedRoute } from "./components/AuthLoadedRoute"
import LoginPage from "./components/LoginPage"
import NotAccessiblePage from "./components/NotAccessiblePage"
import NotFound from "./components/NotFound"
import OsClientPage from "./components/OsClientPage"
import { OsConfigurationPage } from "./components/OsConfigurationPage"
import OsHomePage from "./components/OsHomePage"
import OsIamPage from "./components/OsIamPage"
import OsLockerControllerPage from "./components/OsLockerControllerPage"
import OsLockerPage from "./components/OsLockerPage"
import OsLockerWidget from "./components/OsLockerWidget"
import OsLockersPage from "./components/OsLockersPage"
import OsLockersPageEmbedded from "./components/OsLockersPageEmbedded"
import OsReportingPage from "./components/OsReportingPage"
import OsReservationsPage from "./components/OsReservationsPage"
import PrivateHomePage from "./components/PrivateHomePage"
import { ProtectedRoute } from "./components/ProtectedRoute"
import SaAdminLogsPage from "./components/SaAdminLogsPage"
import SaDeleteThingsPage from "./components/SaDeleteThingsPage"
import SaEmailExpeditorsPage from "./components/SaEmailExpeditorsPage"
import SaHomePage from "./components/SaHomePage"
import SaLockerPage from "./components/SaLockerPage"
import SaLockersPage from "./components/SaLockersPage"
import SaMeventsPage from "./components/SaMeventsPage"
import SaNotifDeliveriesPage from "./components/SaNotifDeliveriesPage"
import SaOrgMembersPage from "./components/SaOrgMembersPage"
import SaReservationAudPage from "./components/SaReservationAudPage"
import SaReservationsPage from "./components/SaReservationsPage"
import SaSeedTrackersPage from "./components/SaSeedTrackersPage"
import SaSmsExpeditorsPage from "./components/SaSmsExpeditorsPage"
import SaSstPage from "./components/SaSstPage"
import SaTenantPage from "./components/SaTenantPage"
import SaTenantsPage from "./components/SaTenantsPage"
import SaUsersPage from "./components/SaUsersPage"
import { SignOutPage } from "./components/SignOutPage"
import SignupInfo from "./components/SignupInfo"
import UserOrgsPage from "./components/UsOrgsPage"
import UserProfilePage from "./components/UsProfilePage"
import { useGetMeQuery } from "./features/me/meApi"
import { selectMe } from "./features/me/meSlice"
import { useSearchParamsState } from "./hooks/useSearchParamsState"
import i18n from "./i18n/i18n"
const App: FC = () => {
  const { user } = useSelector(selectMe)

  const isSuperAdmin = user?.isSuperAdmin

  const { isAuthenticated, isProcessed, isProcessing, isReady } = useAppAuth()

  const sl2ReadyToCheckAuth = isAuthenticated && isReady

  useGetMeQuery("noId", {
    skip: !sl2ReadyToCheckAuth,
  })

  // get locale from url
  const [locale] = useSearchParamsState("locale", "fr")

  useEffect(() => {
    // be permissive for locale, anything containing 'fr' will be considered as 'fr'
    // and anything containing 'en' will be considered as 'en'
    let _locale = locale?.toLowerCase()
    if (_locale?.includes("fr")) {
      _locale = "fr"
    } else if (_locale?.includes("en")) {
      _locale = "en"
    } else {
      _locale = "fr"
    }

    const language =
      _locale ||
      localStorage.getItem("language") ||
      navigator.language.split("-")[0] ||
      "fr"
    i18n.changeLanguage(language)

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "language") {
        i18n.changeLanguage(event.newValue || "fr")
      }
    }

    window.addEventListener("storage", handleStorageChange)

    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [locale])

  return (
    <Routes>
      <Route element={<AuthLoadedRoute isReady={isReady} />}>
        <Route path="" element={<PublicHomePage />} />
        <Route path="/" element={<PublicHomePage />} />
        <Route path="/signup-info" element={<SignupInfo />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signout" element={<SignOutPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute
            name="OS routes"
            // isAllowed={!!isSignedIn && !!isMblsAuthenticated}
            isAllowed={isAuthenticated}
            isReady={
              // isLoaded && isReady && isAuthProcessed && !isAuthProcessing
              isReady
            }
            // isReady={false}
          />
        }
      >
        <Route path="/us" element={<PrivateHomePage />} />
        <Route path="/os" element={<OsHomePage />} />
        <Route path="/os/iam" element={<OsIamPage />} />
        <Route path="/os/lockerWidget" element={<OsLockerWidget />} />
        <Route path="/os/lockers/:lockerId" element={<OsLockerPage />} />
        <Route
          path="/os/lockers/:lockerId/controller"
          element={<OsLockerControllerPage />}
        />
        <Route path="/os/lockers" element={<OsLockersPage />} />
        <Route
          path="/os/embedded/lockers"
          element={<OsLockersPageEmbedded />}
        />
        <Route path="/os/reservations" element={<OsReservationsPage />} />
        <Route
          path="/os/reservations/:reservationId"
          element={<OsReservationsPage />}
        />
        <Route path="/os/reporting/:subPage" element={<OsReportingPage />} />

        <Route
          path="/os/embedded/configuration"
          element={<OsConfigurationPage />}
        />
        <Route path="/os/configuration" element={<OsConfigurationPage />} />
        <Route path="/os/clients" element={<OsClientPage />} />
        <Route path="/os/clients/:id" element={<OsClientPage />} />
        <Route path="/us/profile" element={<UserProfilePage />} />
        <Route path="/us/organizations" element={<UserOrgsPage />} />
      </Route>
      <Route
        element={
          <ProtectedRoute
            isAllowed={isAuthenticated && !!isSuperAdmin}
            isReady={isReady && !!user}
            redirectPath="/not-found"
            name="Super Admin routes"
          />
        }
      >
        <Route path="/sa/home" element={<SaHomePage />} />
        <Route path="/sa/users" element={<SaUsersPage />} />
        <Route path="/sa/org-members" element={<SaOrgMembersPage />} />
        <Route path="/sa/tenants" element={<SaTenantsPage />} />
        <Route path="/sa/tenants/:tenantId" element={<SaTenantPage />} />
        <Route path="/sa/lockers" element={<SaLockersPage />} />
        <Route path="/sa/lockers/:lockerId" element={<SaLockerPage />} />
        <Route path="/sa/sms-expeditors" element={<SaSmsExpeditorsPage />} />
        <Route path="/sa/event-log" element={<SaMeventsPage />} />
        <Route path="/sa/admin-logs" element={<SaAdminLogsPage />} />
        <Route path="/sa/seed-trackers" element={<SaSeedTrackersPage />} />
        <Route path="/sa/sst" element={<SaSstPage />} />
        <Route path="/sa/reservations" element={<SaReservationsPage />} />
        <Route
          path="/sa/notif-deliveries"
          element={<SaNotifDeliveriesPage />}
        />

        <Route
          path="/sa/reservation-audit"
          element={<SaReservationAudPage />}
        />
        <Route
          path="/sa/email-expeditors"
          element={<SaEmailExpeditorsPage />}
        />
        <Route path="/sa/deleted-things" element={<SaDeleteThingsPage />} />
      </Route>

      {/* catch-all not found page */}
      <Route path="*" element={<NotFound />} />
      <Route path="/not-accessible" element={<NotAccessiblePage />} />
    </Routes>
  )
}

export default App
