import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { noop } from "lodash"
import { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { useUpdateThingsMutation } from "src/features/things/thingsApi"
import { selectSaList } from "src/features/things/thingsSlice"
import { useAppSelector } from "../app/hooks"
import { selectTenantId } from "../features/me/meSlice"
import FormFields, { FormField } from "./FormFields"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

const schema = Joi.object({
  tenantId: Joi.string().required(),
})

export type SaTenantExpeditorSelectorDialogProps = {
  onClose?: () => void
  onComplete?: () => void
  expeditorId: string
  expeditorKey: string
}

export const SaTenantExpeditorSelectorDialog: FC<
  SaTenantExpeditorSelectorDialogProps
> = ({ onClose = noop, onComplete = noop, expeditorId, expeditorKey }) => {
  const { t } = useTranslation()

  const [updateThings, { isLoading }] = useUpdateThingsMutation()

  const tenantId = useAppSelector(selectTenantId)

  const tenants = useAppSelector((state) => selectSaList<Tenant>(state)).filter(
    (t) =>
      // @ts-expect-error
      t.thingTypeMrn === TenantThingTypeMrn && t[expeditorKey] !== expeditorId,
  )

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        const toUpdate = {
          id: data.tenantId,
          thingTypeMrn: TenantThingTypeMrn,
          [expeditorKey]: expeditorId,
        }
        await updateThings({
          body: { things: [toUpdate] },
          params: { tenantId },
        }).unwrap()
      } catch (error) {
        console.log(error)
      } finally {
        onComplete()
      }
    },
    [expeditorId, expeditorKey, onComplete, tenantId, updateThings],
  )

  const { fields } = useMemo(() => {
    const options = tenants.map((tenant) => ({
      id: tenant.id,
      label: tenant.name,
    }))

    return {
      fields: [
        {
          name: "tenantId",
          fieldType: "select",
          options,
          label: t("tenant"),
          placeholder: t("selectTenant"),
          required: true,
          size: "small",
          fullWidth: true,
          disabled: false,
          valueKey: "id",
        } as FormField,
      ],
    }
  }, [tenants, t])

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minWidth={400}
        >
          <Typography> {t("selectTenantToAssociateExpeditor")}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Sl2FormContainer
          onError={(errors: any) => console.log(errors)}
          onSuccess={onSubmit}
          joiSchema={schema}
        >
          <Box sx={{ mt: 4 }} />
          <FormFields fields={fields} />
          <Box sx={{ mt: 4 }} />
          <DialogActions>
            <Sl2Btn onClick={onClose} disabled={isLoading} variant="outlined">
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn type="submit" disabled={isLoading} variant="contained">
              {t("associate")}
            </Sl2Btn>
          </DialogActions>
        </Sl2FormContainer>
      </DialogContent>
    </Dialog>
  )
}
