import { Attribute, DateOperand } from "./report";

// list  of supported report types
export const SupportedReportsList = [
  "ReservationSummary",
  "ReservationPayment",
  "ReservationList",
  "PaymentReservationList",
] as const;

export const SupportedReportsEnum = {
  ReservationSummary: "ReservationSummary",
  ReservationPayment: "ReservationPayment",
  ReservationList: "ReservationList",
  PaymentReservationList: "PaymentReservationList",
};

export const reservationFilterAttributes = [
  { name: "createdAt", type: "date", dbAttribute: "sst_created" },
  { name: "depositDate", type: "date", dbAttribute: "sst_delivery_time" },
  { name: "pickupDate", type: "date", dbAttribute: "sst_reception_time" },
  {
    name: "status",
    type: "multiSelect",
    dbAttribute: "sst_status",
    options: [
      { value: "DRAFT", label: "draft" },
      { value: "CONFIRMED", label: "confirmed" },
      { value: "DELIVERED", label: "delivered" },
      { value: "CLOSE", label: "closed" },
      { value: "EXPIRED", label: "expired" },
      { value: "CANCEL", label: "cancelled" },
      { value: "DELETED", label: "deleted" },
    ],
  },
  {
    name: "amountToCollect",
    type: "number",
    dbAttribute: "amount_to_collect",
  },
  { name: "number", type: "integer", dbAttribute: "sst_number" },
  // { name: "ID", type: "text", dbAttribute: "id" },
  // { name: "ID (Sir Steward)", type: "text", dbAttribute: "sst_id" },
  { name: "doorLabel", type: "text", dbAttribute: "sst_door_label" },
  { name: "doorSize", type: "text", dbAttribute: "sst_door_size" },
  { name: "paymentMethod", type: "text", dbAttribute: "payment_method" },
] as Attribute[];

export const clientFilterAttributes = [
  { name: "firstName", type: "text" },
  { name: "lastName", type: "text" },
  { name: "email", type: "text" },
  { name: "phone", type: "text" },
] as Attribute[];

export const filterOperators = {
  boolean: ["equals", "notEquals"],
  text: ["equals", "notEquals", "contains", "notContain"],
  date: ["equals", "notEquals", "before", "after"],
  multiSelect: ["hasAnyOf", "hasNoneOf"],
  number: [
    "equals",
    "notEquals",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThanOrEqual",
    "lessThan",
  ],
  integer: [
    "equals",
    "notEquals",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThanOrEqual",
    "lessThan",
  ],
};

export const dateOperands: DateOperand[] = [
  {
    name: "lastMonth",
    isRelative: true,
  },
  {
    name: "lastYear",
    isRelative: true,
  },
  {
    name: "lastWeek",
    isRelative: true,
  },
  // nbDaysAgo
  {
    name: "nbDaysAgo",
    isRelative: true,
    associatedFieldType: "number",
  },
  {
    name: "exactDate",
    isRelative: false,
    associatedFieldType: "date",
  },
  {
    name: "today",
    isRelative: false,
  },
  {
    name: "yesterday",
    isRelative: false,
  },
  {
    name: "tomorrow",
    isRelative: false,
  },
];

export const SupportedSortKeys = [
  "r.created_at",
  "r.sst_number",
  "r.sst_status",
  "r.first_name",
  "r.last_name",
  "r.email",
  "r.phone",
  "r.amount_to_collect",
  "r.payment_method",
  "r.sst_payment_collected_amount",
  "r.sst_delivery_time",
  "r.sst_reception_time",
  "r.sst_door_label",
  "r.sst_door_size",
  "r.employee_code",
] as const;

export const SupportedSortOrders = ["asc", "desc"] as const;
