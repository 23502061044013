import { Refresh, Search } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EmailDelivery } from "shared/emailDelivery"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { selectSaList, thingsActions } from "src/features/things/thingsSlice"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useGetThingsQuery } from "../features/things/thingsApi"
import SaNotifDeliveriesEmailDrawer from "./SaNotifDeliveriesEmailDrawer"
import Sl2DataGrid from "./Sl2DataGrid"

const defaultColWidth = 230

type SaNotifDeliveriesEmailProps = {}

export const SaNotifDeliveriesEmail: FC<SaNotifDeliveriesEmailProps> = ({}) => {
  const { t } = useTranslation()

  const [email, _setEmail] = useSearchParamsState("email", "")

  const [localEmail, setLocalEmail] = useState(email)

  const dispatch = useAppDispatch()

  const [params, setParams] = useState<Record<string, any>>({
    "ap.name": SharedAccessPatternSaEnum.SaGetEmailDeliveries,
    "ap.scanIndexForward": true,
    "ap.email": email,
    "ap.limit": 100,
    "ap.nextToken": undefined,
    timestamp: Date.now(),
  })

  const q = useGetThingsQuery({ params }, { skip: !email })

  const setEmail = useCallback(
    (val: string) => {
      dispatch(thingsActions.clearSaList())

      setLocalEmail(() => {
        _setEmail(val)
        return val
      })

      setParams((prev) => {
        return {
          ...prev,
          "ap.nextToken": undefined,
          "ap.email": val,
          timestamp: Date.now(),
        }
      })
    },
    [_setEmail, dispatch],
  )

  const emailDeliveries = useAppSelector((state) =>
    selectSaList<EmailDelivery>(state),
  )

  const [selectedDeliveryId, setSelectedDeliveryId] = useSearchParamsState(
    "selectedDeliveryId",
    "",
  )
  const selectedDelivery = useMemo(
    () =>
      emailDeliveries.find((d) => d.id === selectedDeliveryId) as EmailDelivery,
    [selectedDeliveryId, emailDeliveries],
  )

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] =>
      [
        // id
        {
          field: "id",
          headerName: t("id"),
          minWidth: 250,
          // rendercell for link style and setSelectedDeliveryId on click
          renderCell: (params) => {
            return (
              <Typography
                variant="caption"
                fontFamily={"monospace"}
                onClick={() => setSelectedDeliveryId(params.value as string)}
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {params.value}
              </Typography>
            )
          },
        },

        {
          field: "subject",
          headerName: t("subject"),
          minWidth: defaultColWidth,
        },
        {
          field: "body",
          headerName: t("body"),
          minWidth: defaultColWidth,
        },
        {
          field: "status",
          headerName: t("status"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Chip
                label={params.value}
                variant="outlined"
                size="small"
                color={"primary"}
              />
            )
          },
        },

        {
          field: "isSent",
          headerName: t("isSent"),
          minWidth: defaultColWidth,
        },
        {
          // sentAt
          field: "sentAt",
          headerName: t("sentAt"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return params.value ? new Date(params.value).toLocaleString() : ""
          },
        },
        // fromEmail
        {
          field: "fromEmail",
          headerName: t("fromEmail"),
          minWidth: defaultColWidth,
        },
        // toEmail
        {
          field: "toEmail",
          headerName: t("toEmail"),
          minWidth: defaultColWidth,
        },
        // senderId
        {
          field: "senderId",
          headerName: t("senderId"),
          minWidth: defaultColWidth,
        },
        // receiveId
        {
          field: "receiverId",
          headerName: t("receiverId"),
          minWidth: defaultColWidth,
        },
        // retries
        {
          field: "retries",
          headerName: t("retries"),
          minWidth: defaultColWidth,
        },
        // emailProviderReponse
        {
          field: "emailProviderReponse",
          headerName: t("emailProviderReponse"),
          minWidth: defaultColWidth,
        },
      ] as DataGridProps["columns"],
    [setSelectedDeliveryId],
  )
  const handleKeyPress = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLTextAreaElement>
        | React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (e.key === "Enter") {
        setEmail(localEmail)
      }
    },
    [localEmail, setEmail],
  )

  return (
    <Box>
      <Stack direction="column" spacing={4} p={2}>
        <Stack
          direction="column"
          spacing={1}
          // alignItems="center"
          // justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography variant="h6">{`${t("emailDeliveries")} (${
              emailDeliveries?.length
            })`}</Typography>
            {!(q.isLoading || q.isFetching) && (
              <IconButton onClick={q.refetch}>
                <Refresh />
              </IconButton>
            )}
            {(q.isLoading || q.isFetching) && <CircularProgress />}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxWidth: 1000,
          }}
        >
          <TextField
            label={t("searchByEmail")}
            variant="outlined"
            fullWidth
            size={"small"}
            value={localEmail}
            onChange={(e) => setLocalEmail(e.target.value)}
            onBlur={() => setEmail(localEmail)}
            onKeyUp={handleKeyPress}
          />
          <IconButton onClick={q.refetch}>
            <Search />
          </IconButton>
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box sx={{}}>
            <Sl2DataGrid
              datagridProps={{
                columns: columns(t),
                rows: emailDeliveries,
              }}
              isLoading={q.isLoading || q.isFetching}
              listCount={emailDeliveries.length}
              nextToken={q.data?.meta?.nextToken}
              onNextTokenChange={() =>
                setParams((prev) => {
                  return {
                    ...prev,
                    "ap.nextToken": q.data?.meta?.nextToken,
                    timestamp: Date.now(),
                  }
                })
              }
            />
          </Box>
        </Stack>
      </Stack>
      {selectedDelivery?.id && (
        <SaNotifDeliveriesEmailDrawer
          delivery={selectedDelivery}
          onClose={() => setSelectedDeliveryId("")}
        />
      )}
    </Box>
  )
}

export default SaNotifDeliveriesEmail
