import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown"
import StarIcon from "@mui/icons-material/Star"
import TranslateIcon from "@mui/icons-material/Translate"
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useHover } from "@uidotdev/usehooks"
import { t } from "i18next"
import { isArray, noop } from "lodash"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { MblsConnectorClient } from "src/features/mblsConnector/mblsConnector"
import { Sl2Btn } from "./Sl2Btn"

type OsReservationClientMblsSelectorItemProps = {
  client: MblsConnectorClient
  onSelectClient?: () => void
  highlightedPhone?: string
  highlightedEmail?: string
}

export const OsReservationClientMblsSelectorItem: FC<
  OsReservationClientMblsSelectorItemProps
> = (props) => {
  const { client } = props
  const { id } = client

  const [ref, hovering] = useHover()

  const [expanded, setExpanded] = useState(false)

  return (
    <Box
      sx={{
        borderTop: expanded ? "3px solid #234285" : "inherit",
        borderLeft: !expanded ? "3px solid #234285" : "inherit",
        borderRadius: 1,
      }}
    >
      <Paper
        variant="outlined"
        // elevation={hovering ? 2 : 0}
        key={id}
        sx={{
          p: 1,
          px: 3,
          maxHeight: expanded ? "inherit" : "150px",
          overflow: "hidden",
        }}
        ref={ref}
      >
        {expanded ? (
          <Expanded
            {...props}
            onExpandedClick={() => setExpanded(!expanded)}
            hovering={hovering}
          />
        ) : (
          <Collasped
            {...props}
            onExpandedClick={() => setExpanded(!expanded)}
            hovering={hovering}
          />
        )}
      </Paper>
    </Box>
  )
}

const Collasped: FC<
  OsReservationClientMblsSelectorItemProps & {
    onExpandedClick: () => void
    hovering: boolean
  }
> = ({
  client,
  onSelectClient,
  onExpandedClick,
  hovering,
  highlightedEmail,
  highlightedPhone,
}) => {
  const { firstName, lastName, email, phones = [], id, language } = client
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      className={`uf-search-result ${
        !client.email && !client.phones?.[0]?.number
          ? "uf-result-no-contact"
          : "uf-result-w-contact"
      }`}
      sx={{
        color: hovering ? "primary.main" : "inherit",
      }}
    >
      <Stack direction="column" spacing={1}>
        <Stack direction="column" spacing={1} width={"100%"}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {!firstName && !lastName ? (
                <Typography variant="body1" fontWeight={"bold"}>
                  {t("client")} {id?.slice(-10)}
                </Typography>
              ) : (
                <Typography variant="body1" fontWeight={"bold"}>
                  {firstName} {lastName}
                </Typography>
              )}
              <IconButton onClick={onExpandedClick}>
                <ExpandCircleDownIcon
                  fontSize="small"
                  sx={{
                    color: "gray",
                    // transform: "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Stack>
          </Stack>

          <Typography variant="body2">{`✉️ ${
            highlightedEmail || email || t("noEmail")
          }`}</Typography>
        </Stack>
        {phones?.length > 0 && (
          <CollapsedPhoneSection
            phones={phones}
            onMorePhoneClick={onExpandedClick}
            highlightedPhone={highlightedPhone}
          />
        )}
        {language && (
          <Stack direction="row" spacing={1} alignItems="center">
            <TranslateIcon fontSize="small" />
            <Typography variant="body2">{t(language)}</Typography>
          </Stack>
        )}
      </Stack>
      {onSelectClient && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Sl2Btn
            onClick={() => onSelectClient()}
            color="primary"
            className={`uf-select-client-button`}
            variant="outlined"
          >
            {t("select")}
          </Sl2Btn>
        </Stack>
      )}
    </Stack>
  )
}

const Expanded: FC<
  OsReservationClientMblsSelectorItemProps & {
    onExpandedClick: () => void
    hovering: boolean
  }
> = ({
  client,
  onSelectClient,
  onExpandedClick,
  hovering,
  highlightedPhone,
  highlightedEmail,
}) => {
  const {
    firstName,
    lastName,
    email,
    phones = [],
    addresses = [],
    language,
    id,
  } = client
  const { t } = useTranslation()

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          // hovered color`
          //   "&:hover": {
          //     color: "primary.main",
          //   },
          color: hovering ? "primary.main" : "inherit",
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          width={"100%"}
          sx={
            {
              // bgcolor: "primary.main",
            }
          }
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {!firstName && !lastName ? (
                <Typography variant="body1" fontWeight={"bold"}>
                  {t("client")} {id?.slice(-10)}
                </Typography>
              ) : (
                <Typography variant="body1" fontWeight={"bold"}>
                  {firstName} {lastName}
                </Typography>
              )}
              <IconButton
                className={`uf-expand-client-button`}
                onClick={onExpandedClick}
              >
                <ExpandCircleDownIcon
                  fontSize="small"
                  sx={{
                    color: "gray",
                    transform: "rotate(180deg)",
                  }}
                />
              </IconButton>
            </Stack>

            {onSelectClient && (
              <Sl2Btn
                onClick={() => onSelectClient()}
                color="primary"
                variant="outlined"
              >
                {t("select")}
              </Sl2Btn>
            )}
          </Stack>
          <Divider />
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1}>
        <Typography variant="body2" fontWeight="bold">
          {`✉️ ${t("email")}`}
        </Typography>
        <Typography variant="body2">{` ${email || t("noEmail")}`}</Typography>
        {!!highlightedEmail && highlightedEmail !== email && (
          <Stack direction="row" spacing={1}>
            <Typography variant="body2">{` ${highlightedEmail}`}</Typography>
            <Tooltip
              title={
                <Typography variant="body2">
                  {t("phoneUsedInReservation")}
                </Typography>
              }
              placement="top"
            >
              <StarIcon
                sx={{
                  color: "#FAAF01",
                }}
              />
            </Tooltip>
          </Stack>
        )}
      </Stack>

      <PhoneList phones={phones} highlightedPhone={highlightedPhone} />
      <Addresses addresses={addresses} />
      {language && (
        <Stack direction="row" spacing={1} alignItems="center">
          <TranslateIcon fontSize="small" />
          <Typography variant="body2">{t(language)}</Typography>
        </Stack>
      )}
    </Stack>
  )
}

const Phone: FC<{
  phone: {
    id?: string
    number?: string
    label?: string
  }
}> = ({ phone }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {phone?.number ? (
        <Typography variant="body2">{`${phone.number}`}</Typography>
      ) : (
        <Typography variant="body2">{`📞 ${t("noPhone")}`}</Typography>
      )}
      {phone?.label && <Chip label={phone.label} size="small" />}
    </Stack>
  )
}

const PhoneList: FC<{
  phones: {
    id?: string
    number?: string
    label?: string
  }[]
  highlightedPhone?: string
}> = ({ phones = [], highlightedPhone }) => {
  const { t } = useTranslation()

  const _phones = isArray(phones) ? [...phones] : []

  const isHighlightPhoneNew =
    !!highlightedPhone && !_phones.find((p) => p.number === highlightedPhone)

  if (isHighlightPhoneNew) {
    _phones.push({
      id: "new",
      number: highlightedPhone,
      label: "custom",
    })
  }
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body2" fontWeight="bold">
        {`📞 ${t("phones")}`}
      </Typography>
      {_phones.map((phone) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            direction="column"
            spacing={1}
            // alignItems="center"
            key={phone.id}
          >
            <Phone phone={phone} />
            <Divider />
          </Stack>
          {phone.number === highlightedPhone && !!highlightedPhone && (
            <Box
              sx={{
                pb: "6px",
              }}
            >
              {/* tooltip */}
              <Tooltip
                title={
                  <Typography variant="body2">
                    {t("phoneUsedInReservation")}
                  </Typography>
                }
                placement="top"
              >
                <StarIcon
                  sx={{
                    color: "#FAAF01",
                  }}
                />
              </Tooltip>
            </Box>
          )}
        </Stack>
      ))}
    </Stack>
  )
}

const CollapsedPhoneSection: FC<{
  phones: {
    id: string
    number: string
    label: string
  }[]
  onMorePhoneClick?: () => void
  highlightedPhone?: string
}> = ({ phones = [], onMorePhoneClick = noop, highlightedPhone }) => {
  const { t } = useTranslation()
  const firstPhone = highlightedPhone
    ? {
        id: "new",
        number: highlightedPhone,
        label: "custom",
      }
    : phones[0]
  return (
    <>
      {!!firstPhone ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <Phone phone={firstPhone} />
          {phones.length > 1 && (
            // X more phones links
            <Typography
              variant="body2"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                // textDecoration: "underline",
              }}
              onClick={onMorePhoneClick}
            >{`+${phones.length - 1}`}</Typography>
          )}
        </Stack>
      ) : (
        <Typography variant="body2">{`📞 ${t("noPhone")}`}</Typography>
      )}
    </>
  )
}

const Addresses: FC<{
  addresses: {
    id: string
    street_name: string
    street_number: string
    city: string
    state: string
    postcode: string
  }[]
}> = ({ addresses = [] }) => {
  const { t } = useTranslation()
  const _addresses = isArray(addresses) ? addresses : []
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body2" fontWeight="bold">
        {`📍 ${t("addresses")}`}
      </Typography>
      {_addresses.length > 0 ? (
        <Stack direction="column" spacing={1}>
          {_addresses.map((address) => (
            <Stack
              direction="column"
              spacing={1}
              // alignItems="center"
              key={address.id}
            >
              <Typography variant="body2">{`${address.street_number} ${address.street_name} ${address.city}, ${address.state} ${address.postcode}`}</Typography>
              <Divider />
            </Stack>
          ))}
        </Stack>
      ) : (
        <Typography variant="body2">{` ${t("noAddress")}`}</Typography>
      )}
    </Stack>
  )
}
