import { Close, Refresh } from "@mui/icons-material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { FC, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { useAppSelector } from "src/app/hooks"
import { RootState } from "src/app/store"
import {
  selectSaThingById,
  selectThingById,
} from "src/features/things/thingsSlice"
import useLockerDisplayValues from "src/hooks/useLockerDisplayValues"
import { v4 as uuid } from "uuid"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
  useUpdateThingsMutation,
} from "../features/things/thingsApi"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaLayout from "./SaLayout"
import SaLockerForm from "./SaLockerForm"
import { Sl2Btn } from "./Sl2Btn"
import { UnstyledLink } from "./UnstyledLink"

export const SaLockerPage: FC = () => {
  const { t } = useTranslation()

  //  get lockerId from path p arams
  const { lockerId } = useParams<{ lockerId: string }>()

  const isCreating = lockerId === "new"

  const locker = useAppSelector((s: RootState) =>
    selectSaThingById(s, lockerId || ""),
  ) as Locker

  const lockerDisplay = useLockerDisplayValues(locker)

  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetLockers,
        "ap.ids[0]": lockerId,
      },
    },
    {
      skip: isCreating,
    },
  )

  const tenant = useAppSelector((s: RootState) =>
    selectThingById(s, locker?.tenantId || ""),
  ) as Tenant

  const params = {
    "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
  } as Record<string, string>

  if (!isCreating) {
    params["ap.ids[0]"] = locker?.tenantId
  }
  const qTenant = useGetThingsQuery(
    { params },
    { skip: isCreating ? false : !locker?.tenantId },
  )

  const _refetch = useCallback(() => {
    refetch()
    qTenant.refetch()
  }, [refetch, qTenant])

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  // error state
  const [error, setError] = useState<string | null>(null)

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()

  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const onRefresh = useCallback(async () => {
    // refetchReservations()
    const refreshAction = {
      thingTypeMrn: ActionReqThingTypeMrn,
      executionMode: ActionReqExecutionModeEnum.sync,
      actionType: ActionReqTypeEnum.SstLockerRefresh,
      idempotencyKey: uuid(),
      delaySeconds: 2,
      tenantId: locker?.tenantId,
      inputData: {
        tenantId: locker?.tenantId,
        lockerId,
        sstLockerId: locker?.sstLocker?._id,
      },
    }
    await createThings({
      body: { things: [refreshAction] },
      params: { tenantId: locker?.tenantId },
    }).unwrap()
  }, [locker?.tenantId, locker?.sstLocker?._id, lockerId, createThings])

  const [sstLockerPreviewDialogOpen, setSstLockerPreviewDialogOpen] =
    useState(false)

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)

  const [deleteError, setDeleteError] = useState<string | null>(null)

  const deleteAction = useCallback(async () => {
    try {
      setIsDeleting(true)
      setDeleteError(null)

      const action = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        actionType: ActionReqTypeEnum.DeleteThing,
        idempotencyKey: uuid(),

        tenantId: locker?.tenantId,
        inputData: {
          tenantId: locker?.tenantId,
          id: lockerId,
          thingTypeMrn: LockerThingTypeMrn,
        },
      }
      const res = await createThings({
        body: { things: [action] },
        params: { tenantId: locker?.tenantId },
      }).unwrap()

      if (res.things?.[0].errors) {
        throw new Error(res.things[0].errors[0].message)
      }
    } catch (error: any) {
      setDeleteError(error.message)
    } finally {
      setIsDeleting(false)
    }
  }, [locker?.tenantId, lockerId, createThings])

  return (
    <SaLayout selectedMenuItemLabelKey="lockers">
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4} maxWidth="lg">
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <UnstyledLink to="/sa/lockers">
              <Typography variant="h6">{t("lockers")}</Typography>
            </UnstyledLink>
            <ChevronRightIcon />
            {locker?.id ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h6" fontWeight={"bold"}>
                  {lockerDisplay.i18n.name}
                </Typography>
                {locker?.isVirtual && (
                  <Chip label={t("virtual")} size="small" color="primary" />
                )}
              </Stack>
            ) : (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h6" fontWeight={"bold"}>
                  {t("newLocker")}
                </Typography>
              </Stack>
            )}
            {(isLoading || isUpdatingThings || isCreatingThings) && (
              <CircularProgress size={20} />
            )}
          </Stack>
          <IconButton onClick={_refetch}>
            <Refresh />
          </IconButton>
        </Stack>

        {locker?.id && (
          <Stack direction="column" spacing={3}>
            <Stack
              direction="row"
              spacing={10}
              alignItems="center"
              // justifyContent={"space-between"}
            >
              <Stack direction="column" spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  {t("tenant")}
                </Typography>
                {qTenant.isLoading || qTenant.isFetching ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Link to={`/sa/tenants/${tenant?.id}`}>
                      <Typography variant="body1">
                        {tenant?.name || "-"}
                      </Typography>
                    </Link>
                    <Typography variant="caption">{tenant?.id}</Typography>
                  </>
                )}
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography variant="body1" fontWeight="bold">
                  {t("sstLockerAssociated")}
                </Typography>
                {!locker?.sstLocker ? (
                  <Typography variant="body1">
                    {t("noLockerAssociated")}
                  </Typography>
                ) : (
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="body2" fontFamily={"monospace"}>
                        {locker?.sstLocker?._id}
                      </Typography>
                      <Sl2Btn
                        variant="contained"
                        fullWidth={false}
                        size="small"
                        onClick={() => setSstLockerPreviewDialogOpen(true)}
                      >
                        {t("open")}
                      </Sl2Btn>
                    </Stack>
                    {locker?.lastSstSync && (
                      <Typography variant="caption">
                        {`${t("lastSync")}: ${moment(
                          locker?.lastSstSync,
                        ).format("YYYY-MM-DD HH:mm:ss")}`}
                      </Typography>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack direction="column" spacing={1} width="700px">
              <Typography variant="body1" fontWeight="bold">
                {t("details")}
              </Typography>
              <SaLockerForm locker={locker} isCreating={isCreating} />
            </Stack>
            <Divider />
            <Stack direction="column" spacing={1} width="700px">
              {/* delete locker */}
              <Typography variant="body1" fontWeight="bold">
                {t("lockerDelete")}
              </Typography>
              <Alert severity="error">{t("lockerDeleteWarning")}</Alert>
              <Box>
                <Sl2Btn
                  variant="contained"
                  color="error"
                  onClick={() => setIsConfirmingDelete(true)}
                  disabled={isDeleting}
                >
                  {t("delete")}
                </Sl2Btn>
              </Box>
              <Dialog
                open={isConfirmingDelete}
                onClose={() => setIsConfirmingDelete(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>{t("confirmDelete")}</DialogTitle>
                <DialogContent>
                  <Stack direction="column" spacing={1}>
                    <DialogContentText>
                      {t("confirmDeleteLockerWarning")}
                    </DialogContentText>
                    {deleteError && (
                      <DialogContentText color={"error"}>
                        {deleteError}
                      </DialogContentText>
                    )}
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Sl2Btn
                    onClick={() => setIsConfirmingDelete(false)}
                    disabled={isDeleting}
                  >
                    {t("cancel")}
                  </Sl2Btn>
                  <Sl2Btn
                    onClick={async () => {
                      try {
                        await deleteAction()
                        setIsConfirmingDelete(false)

                        window.location.href = "/sa/lockers"
                      } catch (error: any) {}
                    }}
                    variant="contained"
                    color="error"
                    disabled={isDeleting}
                  >
                    {t("delete")}
                  </Sl2Btn>
                </DialogActions>
              </Dialog>
            </Stack>
            <Divider />
            <Footer locker={locker} />
          </Stack>
        )}

        {isCreating && (
          <Stack direction="column" spacing={1} width="700px">
            <SaLockerForm locker={locker} isCreating={true} />
          </Stack>
        )}
        {/* {lockerId}
        {locker?.name} */}
      </Stack>
      <Drawer
        anchor="right"
        open={sstLockerPreviewDialogOpen}
        onClose={() => setSstLockerPreviewDialogOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw",
            maxWidth: "1500px",
            maxHeight: "calc(100vh - 64px)",
            height: "calc(100vh - 64px)",
            paddingTop: "64px",
          },
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">{t("sstLocker")}</Typography>
              {isCreatingThings ? (
                <CircularProgress />
              ) : (
                <IconButton onClick={onRefresh}>
                  <Refresh />
                </IconButton>
              )}
            </Stack>
            <IconButton onClick={() => setSstLockerPreviewDialogOpen(false)}>
              <Close />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            {/* last syync */}
            <Typography variant="caption">{t("lastSync")}</Typography>
            <Typography variant="caption">
              {moment(locker?.lastSstSync).fromNow()}
            </Typography>
            <Typography variant="caption">
              {moment(locker?.lastSstSync).format("YYYY-MM-DD HH:mm:ss")}
            </Typography>
            {/* fromNow */}
          </Stack>
          <ObjectDisplayTable data={locker?.sstLocker} />
        </Stack>
      </Drawer>
    </SaLayout>
  )
}

export default SaLockerPage

const Footer: FC<{ locker: Locker }> = ({ locker }) => {
  const { t } = useTranslation()
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption">{t("createdAt")}</Typography>
        <Typography variant="caption">
          {moment(locker.createdAt).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
        <Typography variant="caption">-</Typography>
        <Typography variant="caption">{t("updatedAt")}</Typography>

        <Typography variant="caption">
          {moment(locker.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      </Stack>
      <Typography variant="caption">{locker.id}</Typography>
    </Stack>
  )
}
