import { Close, Refresh } from "@mui/icons-material"
import {
  Chip,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady } from "../features/me/meSlice"
import AdminLogComponent from "./AdminLog"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaLayout from "./SaLayout"

export const SaAdminLogsPage: FC = () => {
  const { t } = useTranslation()

  const isReady = useAppSelector(selectMeIsReady)
  const [status, setStatus] = useSearchParamsState("status", "")
  const qLog = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetAdminLogs,
        "ap.status": status ? status : undefined,
      },
    },
    { skip: !isReady },
  )

  // osrt by createdAt desc
  const logs = useMemo(
    () =>
      [...(qLog.data?.things || [])].sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      }),
    [qLog.data],
  )

  const [selectedKeys, setSelectedKeys] = useState<string>("")
  const [selectedTitle, setSelectedTitle] = useState<string>("")

  const qItem = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.GetItemByKeys,
        "ap.keys": selectedKeys,
      },
    },
    { skip: !selectedKeys },
  )
  const item = useMemo(() => qItem.data?.things[0], [qItem.data])

  return (
    <SaLayout selectedMenuItemLabelKey="adminLog">
      <Stack
        direction="column"
        spacing={2}
        sx={{ p: 2, height: "fit-content" }}
      >
        <Stack direction="row" spacing={2} sx={{}}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
               <Typography variant="h6">{t("adminLogs")}</Typography> 
            </Stack>
            <Typography variant="body2">{t("adminLogsDescription")}</Typography>
          </Stack>
        </Stack>
        <Divider /> 
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">{`${logs?.length} logs`}</Typography> 
          <IconButton size="small" onClick={qLog.refetch}>
            <Refresh />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          {/* filter status : resue same Chip color */}
          <Typography variant="body1">{t("filters")}</Typography>
          <Chip
            label={t("all")}
            color={status ? "default" : "primary"}
            onClick={() => setStatus("")}
            size="small"
          />
          <Chip
            label={t("resolved")}
            color={status === "resolved" ? "primary" : "success"}
            onClick={() => setStatus("resolved")}
            size="small"
          />
          <Chip
            label={t("alarm")}
            color={status === "alarm" ? "primary" : "error"}
            onClick={() => setStatus("alarm")}
            size="small"
          />
        </Stack>
        {logs.map((log) => (
          <AdminLogComponent
            key={log.id}
            log={log}
            onSelectKeys={(keys, title) => {
              setSelectedKeys(keys)
              setSelectedTitle(title)
            }}
            onResolved={qLog.refetch}
          />
        ))}
      </Stack>
      {item && selectedKeys && (
        <Drawer
          anchor="right"
          open={true}
          onClose={() => setSelectedKeys("")}
          sx={{
            "& .MuiDrawer-paper": {
              width: "80vw",
              maxWidth: "1500px",
              maxHeight: "calc(100vh - 64px)",
              height: "calc(100vh - 64px)",
              paddingTop: "64px",
            },
          }}
        >
          <Stack direction="column" spacing={1} p={1}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" fontWeight="bold">
                {selectedTitle}
              </Typography>
              <IconButton onClick={() => setSelectedKeys("")}>
                <Close />
              </IconButton>
            </Stack>
            <ObjectDisplayTable data={item} isLoading={qItem.isLoading} />
          </Stack>
        </Drawer>
      )}
    </SaLayout>
  )
}

export default SaAdminLogsPage
