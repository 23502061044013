import { Send } from "@mui/icons-material"
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import { noop } from "lodash"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { useAppSelector } from "src/app/hooks"
import { selectTenantId } from "src/features/me/meSlice"
import { useCreateThingsMutation } from "src/features/things/thingsApi"
import { v4 as uuidv4 } from "uuid"
import { EmailPreview } from "./EmailPreview"
import { Sl2Btn } from "./Sl2Btn"
import { SmsPreview } from "./SmsPreview"

type OrderReminderNotifPreviewProps = {
  onOk?: () => void
  onCancel?: () => void
  reservationId: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

export const OrderReminderNotifPreview: FC<OrderReminderNotifPreviewProps> = ({
  onOk = noop,
  onCancel = noop,
  reservationId,
  firstName,
  lastName,
  email,
  phone,
}) => {
  const { t } = useTranslation()

  const tenantId = useAppSelector(selectTenantId)

  const [createThings] = useCreateThingsMutation()

  const [isFetchingPreview, setIsFetchingPreview] = useState(false)

  const [generatedPreview, setGeneratedPreview] = useState<{
    sms: Record<string, any>
    email: { toEmail: string; fromEmail: string; subject: string; body: string }
  }>()

  const onGetNotificationPreview = useCallback(async () => {
    setIsFetchingPreview(true)
    const inputData: { [key: string]: string | undefined } = {
      reservationId,
      tenantId,
      templateName: "orderPickupReminderTemplate",
      firstName,
      lastName,
      email,
      phone,
    }
    // remove null values
    Object.keys(inputData).forEach(
      (key) =>
        (inputData[key] === null || inputData[key] === "") &&
        delete inputData[key],
    )

    const actionReq = {
      thingTypeMrn: ActionReqThingTypeMrn,
      tenantId,
      idempotencyKey: uuidv4(),
      actionType: ActionReqTypeEnum.GetNotificationPreview,
      executionMode: ActionReqExecutionModeEnum.sync,
      inputData,
    }
    try {
      const res = await createThings({
        body: { things: [actionReq] },
        params: { tenantId },
      }).unwrap()

      const resultData = res.things[0]?.resultData
      if (!resultData) {
        throw new Error("No result data")
      }
      setGeneratedPreview(resultData)
    } catch (error: any) {
      console.error(error)
    } finally {
      setIsFetchingPreview(false)
    }
  }, [createThings, email, firstName, lastName, phone, reservationId, tenantId])

  useEffect(() => {
    onGetNotificationPreview()
  }, [onGetNotificationPreview])

  const unableToSendNotif = useMemo(() => {
    return (
      !generatedPreview?.email && !generatedPreview?.sms && !isFetchingPreview
    )
  }, [generatedPreview?.email, generatedPreview?.sms, isFetchingPreview])

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <DialogTitle>{t("confirmDeliveryToClient")}</DialogTitle>
      <Divider />
      <DialogContent sx={{ minHeight: 200 }}>
        <Stack direction="column" spacing={4}>
          <Stack direction="row" spacing={4} alignItems="center">
            {(generatedPreview?.email || isFetchingPreview) && (
              <Box
                sx={{
                  width: "50%",
                  // pr: 1,
                }}
              >
                <EmailPreview
                  isFetchingPreview={isFetchingPreview}
                  fullName={`${firstName} ${lastName}`}
                  toEmail={generatedPreview?.email?.toEmail}
                  fromEmail={generatedPreview?.email?.fromEmail}
                  subject={generatedPreview?.email?.subject}
                  body={generatedPreview?.email?.body}
                />
              </Box>
            )}
            {(generatedPreview?.sms || isFetchingPreview) && (
              <Box
                sx={{
                  width: "50%",
                }}
              >
                <SmsPreview
                  isFetchingPreview={isFetchingPreview}
                  fullName={`${firstName} ${lastName}`}
                  toPhoneNumber={generatedPreview?.sms?.toPhoneNumber}
                  fromPhoneNumber={generatedPreview?.sms?.fromPhoneNumber}
                  message={generatedPreview?.sms?.message}
                />
              </Box>
            )}
            {/* if neither generatedPreview?.email nor generatedPreview?.ss display error message */}
            {unableToSendNotif && (
              <Alert severity="error">{t("noPreviewAvailable")}</Alert>
            )}
          </Stack>
          {!unableToSendNotif && (
            <DialogContentText justifyContent={"center"}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent={"center"}
              >
                <Typography fontWeight={"bold"}>
                  {t("confirmDeliveryToClientDescription")}
                </Typography>
              </Stack>
            </DialogContentText>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack direction="row" spacing={1} alignItems="center">
          {/* OK CANCEL BTNS */}
          <Sl2Btn variant="outlined" onClick={onCancel}>
            {" "}
            {t("cancel")}
          </Sl2Btn>
          <Sl2Btn
            variant="contained"
            color="primary"
            startIcon={<Send />}
            onClick={onOk}
          >
            {t("send")}
          </Sl2Btn>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
