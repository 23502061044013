import { Refresh } from "@mui/icons-material"
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Client } from "shared/client"
import { ClientThingTypeMrn } from "shared/clientData"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "src/features/things/thingsSlice"
import { useAppSelector } from "../app/hooks"
import { selectTenantId } from "../features/me/meSlice"
// import { OsClientItem } from "./OsClientItem"
import { DataGrid } from "@mui/x-data-grid"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import ClientDrawer from "./ClientDrawer"
import OsLayout from "./OsLayout"
import { Sl2Btn } from "./Sl2Btn"

export const OsClientPage: FC = () => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)

  const [drawerOpen, setDrawerOpen] = useState(false)

  const [selectedClient, _setSelectedClient] = useState<Client | null>(null)

  const setSelectedClient = useCallback((client: Client | null) => {
    _setSelectedClient(client)
    setDrawerOpen(true)
  }, [])

  const { id } = useParams<{ id: string | undefined }>()

  const navigate = useNavigate()

  const location = useLocation()

  const onNewClientClick = useCallback(() => {
    navigate(`/os/clients${location.search}`)
    setSelectedClient(null)
  }, [location.search, navigate, setSelectedClient])

  const handleDrawerClose = () => {
    navigate(`/os/clients${location.search}`)
    setSelectedClient(null)
    setDrawerOpen(false)
  }
  const { isLoading, refetch } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetClients,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )
  const clients = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, ClientThingTypeMrn),
  ) as Client[] | undefined

  useEffect(() => {
    if (id) {
      // find client by id
      const client = clients?.find((c) => c.id === id)
      if (client) setSelectedClient(client)
    }
  }, [clients, id, setSelectedClient])

  // firstName, lastName, email, phone, status, mblsClientId, notes
  const columns = useMemo(
    () => [
      {
        field: "firstName",
        headerName: t("firstName"),
        width: 150,
      },
      {
        field: "lastName",
        headerName: t("lastName"),
        width: 150,
      },
      {
        field: "email",
        headerName: t("email"),
        width: 200,
      },
      {
        field: "phone",
        headerName: t("phone"),
        width: 150,
      },
      {
        field: "language",
        headerName: t("language"),
        width: 150,
      },
      {
        field: "mblsClientId",
        headerName: t("mblsClientId"),
        width: 150,
      },
      {
        field: "notes",
        headerName: t("notes"),
        width: 150,
      },
    ],
    [t],
  )

  const [snackMessage, setSnackMessage] = useState({
    message: "",
    severity: "success" as "success" | "error" | "warning" | "info" | undefined,
  })

  return (
    <OsLayout selectedMenuItemLabelKey="clientModule">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Stack direction="column" spacing={5} p={2} pl={4} mt={4}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h5">{`${t("clientModule")} (${
                clients?.length || 0
              })`}</Typography>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  onClick={() => refetch()}
                  color="primary"
                  size="small"
                >
                  <Refresh />
                </IconButton>
              )}
            </Stack>
            <Typography variant="body1">
              {t("clientModuleDescription")}
            </Typography>
          </Stack>
          <Box>
            <Sl2Btn
              variant="contained"
              color="primary"
              onClick={onNewClientClick}
            >
              {t("addClient")}
            </Sl2Btn>
          </Box>
          <Box
            sx={{
              mt: 6,
              width: "fit-content",
              minHeight: 300,
            }}
          >
            <DataGrid
              columns={columns}
              rows={clients || []}
              localeText={{
                noRowsLabel: isLoading ? "Loading ..." : t("noClients"),
              }}
              autoHeight
              rowSelection={true}
              onRowClick={(row) => {
                // navigate to client detail page; include existing query params
                navigate(`/os/clients/${row.row.id}${location.search}`)
              }}
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
            />
          </Box>
        </Stack>
        {drawerOpen && (
          <ClientDrawer
            open={true}
            onClose={handleDrawerClose}
            client={selectedClient}
            onCreated={() => {
              setSnackMessage({
                message: t("clientCreated"),
                severity: "success",
              })
            }}
            onUpdated={() => {
              setSnackMessage({
                message: t("clientUpdated"),
                severity: "success",
              })
            }}
            onError={() => {
              setSnackMessage({
                message: t("clientError"),
                severity: "error",
              })
            }}
          />
        )}
      </Stack>
      <Snackbar
        open={!!snackMessage?.message}
        autoHideDuration={6000}
        onClose={() => setSnackMessage({ message: "", severity: undefined })}
      >
        <Alert
          severity={snackMessage.severity}
          sx={{
            minWidth: "500px",
          }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
    </OsLayout>
  )
}

export default OsClientPage
