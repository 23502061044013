import { Box, Stack } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Toolbar from "@mui/material/Toolbar"
import React from "react"
import { appConfig } from "../appConfig"
import MainAppbar from "./MainAppbar"

interface MainLayoutProps {
  children: React.ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      <MainAppbar />
      <Drawer
        variant="permanent"
        sx={{
          width: appConfig.mainMenuWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: appConfig.mainMenuWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <List>
          {["Menu Item 1", "Menu Item 2", "Menu Item 3"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Box mt={6} />
      </Drawer>
      <main style={{ flexGrow: 1, padding: "1rem" }}>
        <Toolbar />
        <Stack spacing={2} direction="row">
          {/* <Box width={appConfig.drawerWidth} /> */}
          <Box>{children}</Box>
        </Stack>
      </main>
    </Box>
  )
}

export default MainLayout
