import { Close, Refresh, Search } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { SmsDelivery } from "shared/smsDelivery"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { selectSaList, thingsActions } from "src/features/things/thingsSlice"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useGetThingsQuery } from "../features/things/thingsApi"
import ObjectDisplayTable from "./ObjectDisplayTable"
import Sl2DataGrid from "./Sl2DataGrid"

const defaultColWidth = 230

type SaNotifDeliveriesSmsProps = {}

export const SaNotifDeliveriesSms: FC<SaNotifDeliveriesSmsProps> = ({}) => {
  const { t } = useTranslation()

  const [phoneNumber, _setPhoneNumber] = useSearchParamsState("phoneNumber", "")

  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber)

  const dispatch = useAppDispatch()

  const [params, setParams] = useState<Record<string, any>>({
    "ap.name": SharedAccessPatternSaEnum.SaGetSmsDeliveries,
    "ap.scanIndexForward": true,
    "ap.phoneNumber": phoneNumber,
    "ap.limit": 100,
    "ap.nextToken": undefined,
    timestamp: Date.now(),
  })

  const q = useGetThingsQuery({ params }, { skip: !phoneNumber })

  const setPhoneNumber = useCallback(
    (val: string) => {
      dispatch(thingsActions.clearSaList())

      setLocalPhoneNumber(val)

      _setPhoneNumber(val)

      setParams((prev) => {
        return {
          ...prev,
          "ap.nextToken": undefined,
          "ap.phoneNumber": val,
          timestamp: Date.now(),
        }
      })
    },
    [_setPhoneNumber, dispatch],
  )

  const smsDeliveries = useAppSelector((state) =>
    selectSaList<SmsDelivery>(state),
  )

  const [selectedDeliveryId, setSelectedDeliveryId] = useSearchParamsState(
    "selectedDeliveryId",
    "",
  )
  const selectedDelivery = useMemo(
    () => smsDeliveries.find((d) => d.id === selectedDeliveryId) as SmsDelivery,
    [selectedDeliveryId, smsDeliveries],
  )

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] =>
      [
        {
          field: "id",
          headerName: t("id"),
          minWidth: 250,
          // rendercell for link style and setSelectedDeliveryId on click
          renderCell: (params) => {
            return (
              <Typography
                variant="caption"
                fontFamily={"monospace"}
                onClick={() => setSelectedDeliveryId(params.value as string)}
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {params.value}
              </Typography>
            )
          },
        },
        // message
        {
          field: "message",
          headerName: t("message"),
          minWidth: defaultColWidth,
        },
        {
          field: "status",
          headerName: t("status"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Chip
                label={params.value}
                variant="outlined"
                size="small"
                color={"primary"}
              />
            )
          },
        },

        {
          field: "isSent",
          headerName: t("isSent"),
          minWidth: defaultColWidth,
        },
        {
          // sentAt
          field: "sentAt",
          headerName: t("sentAt"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return params.value ? new Date(params.value).toLocaleString() : ""
          },
        },
        {
          field: "twilioStatus",
          headerName: t("twilioStatus"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            if (!params.value) {
              return null
            }
            return (
              <Chip
                label={params.value}
                variant="outlined"
                size="small"
                color={"primary"}
              />
            )
          },
        },
        // deliveredAt
        {
          field: "deliveredAt",
          headerName: t("deliveredAt"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return params.value ? new Date(params.value).toLocaleString() : ""
          },
        },
        // fromPhoneNumber
        {
          field: "fromPhoneNumber",
          headerName: t("fromPhoneNumber"),
          minWidth: defaultColWidth,
        },
        // toPhoneNumber
        {
          field: "toPhoneNumber",
          headerName: t("toPhoneNumber"),
          minWidth: defaultColWidth,
        },
        // senderId
        {
          field: "senderId",
          headerName: t("senderId"),
          minWidth: defaultColWidth,
        },
        // receiveId
        {
          field: "receiverId",
          headerName: t("receiverId"),
          minWidth: defaultColWidth,
        },
        // retries
        {
          field: "retries",
          headerName: t("retries"),
          minWidth: defaultColWidth,
        },
        // smsProviderReponse
        {
          field: "smsProviderReponse",
          headerName: t("smsProviderReponse"),
          minWidth: defaultColWidth,
        },
      ] as DataGridProps["columns"],
    [setSelectedDeliveryId],
  )
  const handleKeyPress = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLTextAreaElement>
        | React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (e.key === "Enter") {
        setPhoneNumber(localPhoneNumber)
      }
    },
    [localPhoneNumber, setPhoneNumber],
  )

  return (
    <Box>
      <Stack direction="column" spacing={4} p={2}>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h6">{`${t("smsDeliveries")} (${
            smsDeliveries?.length
          })`}</Typography>
          {!(q.isLoading || q.isFetching) && (
            <IconButton onClick={q.refetch}>
              <Refresh />
            </IconButton>
          )}
          {(q.isLoading || q.isFetching) && <CircularProgress />}
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxWidth: 1000,
          }}
        >
          <TextField
            label={t("searchByPhoneNumber")}
            variant="outlined"
            fullWidth
            size={"small"}
            value={localPhoneNumber}
            onChange={(e) => setLocalPhoneNumber(e.target.value)}
            onBlur={() => setPhoneNumber(localPhoneNumber)}
            onKeyUp={handleKeyPress}
          />
          <IconButton onClick={q.refetch}>
            <Search />
          </IconButton>
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box sx={{}}>
            <Sl2DataGrid
              datagridProps={{
                rows: smsDeliveries,
                columns: columns(t),
              }}
              isLoading={q.isLoading || q.isFetching}
              listCount={smsDeliveries.length}
              nextToken={q.data?.meta?.nextToken}
              onNextTokenChange={() =>
                setParams((prev) => {
                  return {
                    ...prev,
                    "ap.nextToken": q.data?.meta?.nextToken,
                    timestamp: Date.now(),
                  }
                })
              }
            />
          </Box>
        </Stack>
      </Stack>
      {selectedDeliveryId && (
        <Drawer
          anchor="right"
          open={!!selectedDeliveryId}
          onClose={() => setSelectedDeliveryId("")}
          sx={{
            "& .MuiDrawer-paper": {
              width: "80vw",
              maxWidth: "1500px",
              maxHeight: "calc(100vh - 64px)",
              height: "calc(100vh - 64px)",
              paddingTop: "64px",
            },
          }}
        >
          <Stack direction="column" spacing={1} p={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    color: "primary.main",
                  }}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {selectedDelivery?.id}
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedDelivery?.status}
                  </Typography>
                </Stack>
              </Stack>
              <IconButton onClick={() => setSelectedDeliveryId("")}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />

            <ObjectDisplayTable
              data={selectedDelivery}
              isLoading={q.isLoading}
            />
          </Stack>
        </Drawer>
      )}
    </Box>
  )
}

export default SaNotifDeliveriesSms
