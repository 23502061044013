import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import parseHtml from "html-react-parser"
import { noop } from "lodash"
import moment from "moment"
import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { AdminLog } from "shared/adminLog"
import { useUpdateThingsMutation } from "src/features/things/thingsApi"
import { lightTheme } from "src/theme"
import { Sl2Btn } from "./Sl2Btn"

type AdminLogProps = {
  log: AdminLog
  onSelectKeys?: (keys: string, titel: string) => void
  onResolved?: () => void
}

const AdminLogComponent: FC<AdminLogProps> = ({
  log,
  onSelectKeys = noop,
  onResolved = noop,
}) => {
  const { t } = useTranslation()

  const [updateThings, { isLoading: isUpdating }] = useUpdateThingsMutation()

  const onResolve = useCallback(async () => {
    try {
      const toUpdate = {
        id: log.id,
        thingTypeMrn: log.thingTypeMrn,
        status: "resolved",
      }
      await updateThings({
        body: { things: [toUpdate] },
      }).unwrap()
      onResolved()
    } catch (error) {
      console.error(error)
    }
  }, [log.id, log.thingTypeMrn, updateThings, onResolved])

  const messageHtml = log.message?.replace(/\n/g, "<br>") || ""
  const relatedThings = log.relatedThings || []
  return (
    <Card
      variant="outlined"
      // left obrder color based on state
      sx={{
        borderLeft: "2px solid",
        borderLeftColor:
          log.status === "alarm"
            ? lightTheme.palette.error.main
            : log.status === "resolved"
            ? lightTheme.palette.success.main
            : "grey",
        borderRadius: 1,
        marginBottom: 1,
      }}
    >
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center" my={1}>
              <Typography variant="body1" fontWeight="bold">
                {moment(log.createdAt).format("YYYY-MM-DD HH:mm:ss:SSS")}
              </Typography>

              {log.status && (
                <Box>
                  <Chip
                    size="small"
                    label={log.status}
                    sx={{
                      bgcolor:
                        log.status === "alarm"
                          ? lightTheme.palette.error.main
                          : log.status === "resolved"
                          ? lightTheme.palette.success.main
                          : "default",
                      color: log.status === "default" ? "black" : "white",
                    }}
                  />
                </Box>
              )}
            </Stack>
            <Sl2Btn
              variant="outlined"
              onClick={onResolve}
              disabled={isUpdating || log.status !== "alarm"}
            >
              {t("resolve")}
            </Sl2Btn>
          </Stack>
          <Divider />
          <Typography variant="caption">{parseHtml(messageHtml)}</Typography>
          <Divider />
          {relatedThings.length > 0 && (
            <Typography variant="body1">{t("additionnalInfo")}</Typography>
          )}
          {relatedThings.map((thing) => {
            return (
              <Box>
                <Chip
                  onClick={() => onSelectKeys(thing.keys, thing.displayName)}
                  key={thing.keys}
                  label={thing.displayName}
                  variant="outlined"
                  size="small"
                  sx={{
                    borderRadius: 1,
                  }}
                />
              </Box>
            )
          })}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default AdminLogComponent
