import { Close, Send } from "@mui/icons-material"
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { noop } from "lodash"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { useAppSelector } from "src/app/hooks"
import { selectTenantId } from "src/features/me/meSlice"
import { useCreateThingsMutation } from "src/features/things/thingsApi"
import {
  selectMblsReservation,
  selectMblsReservationClient,
} from "src/features/things/thingsSlice"
import useClearMblsReservation from "src/hooks/useClearMblsReservation"
import { v4 as uuidv4 } from "uuid"
import { EmailPreview } from "./EmailPreview"
import { Sl2Btn } from "./Sl2Btn"
import { SmsPreview } from "./SmsPreview"

type MblsReservationBannerProps = {
  onClose?: () => void
}

export const MblsReservationBanner: FC<MblsReservationBannerProps> = ({
  onClose = noop,
}) => {
  const { t } = useTranslation()

  const tenantId = useAppSelector(selectTenantId)

  const mblsReservation = useAppSelector(selectMblsReservation)

  const mblsReservationClient = useAppSelector(selectMblsReservationClient)

  const [createThings] = useCreateThingsMutation()

  const [isProcessing, setIsProcessing] = useState(false)

  const [sentStatus, setSentStatus] = useState<"none" | "success" | "error">(
    "none",
  )
  const resovledContactInfo = useMemo(() => {
    return {
      fullName: mblsReservationClient?.firstName
        ? `${mblsReservationClient?.firstName} ${mblsReservationClient?.lastName}`
        : "-",
      email:
        mblsReservation?.smartLockerNotificationEmail ||
        mblsReservationClient?.email ||
        "",
      phone:
        mblsReservation?.smartLockerNotificationPhone ||
        mblsReservationClient?.phones?.[0]?.number ||
        "",
    }
  }, [
    mblsReservationClient?.firstName,
    mblsReservationClient?.lastName,
    mblsReservationClient?.email,
    mblsReservationClient?.phones,
    mblsReservation?.smartLockerNotificationEmail,
    mblsReservation?.smartLockerNotificationPhone,
  ])

  const [isFetchingPreview, setIsFetchingPreview] = useState(false)
  const [generatedPreview, setGeneratedPreview] = useState<{
    sms: Record<string, any>
    email: { toEmail: string; fromEmail: string; subject: string; body: string }
  }>()

  const onGetNotificationPreview = useCallback(async () => {
    setIsFetchingPreview(true)
    const { email, phone } = resovledContactInfo
    const inputData: { [key: string]: string | undefined } = {
      firstName: mblsReservationClient?.firstName,
      lastName: mblsReservationClient?.lastName,
      email,
      phone,
      tenantId,
      templateName: "lockerFullCapacityTemplate",
    }
    // remove null values
    Object.keys(inputData).forEach(
      (key) =>
        (inputData[key] === null || inputData[key] === "") &&
        delete inputData[key],
    )

    const actionReq = {
      thingTypeMrn: ActionReqThingTypeMrn,
      tenantId,
      idempotencyKey: uuidv4(),
      actionType: ActionReqTypeEnum.GetNotificationPreview,
      executionMode: ActionReqExecutionModeEnum.sync,
      inputData,
    }
    try {
      const res = await createThings({
        body: { things: [actionReq] },
        params: { tenantId },
      }).unwrap()

      const resultData = res.things[0]?.resultData
      if (!resultData) {
        throw new Error("No result data")
      }
      setGeneratedPreview(resultData)
    } catch (error: any) {
      console.error(error)
    } finally {
      setIsFetchingPreview(false)
    }
  }, [
    createThings,
    mblsReservationClient?.firstName,
    mblsReservationClient?.lastName,
    resovledContactInfo,
    tenantId,
  ])

  const [showFullCapacityDeliveryDialog, _setShowFullCapacityDeliveryDialog] =
    useState(false)

  const setShowFullCapacityDeliveryDialog = useCallback(
    (show: boolean) => {
      _setShowFullCapacityDeliveryDialog(show)
      setSentStatus("none")
      if (show) {
        onGetNotificationPreview()
      }
    },
    [onGetNotificationPreview],
  )

  const { clearMblsReservation } = useClearMblsReservation()

  const onSendNotifFullCapacity = useCallback(async () => {
    try {
      setIsProcessing(true)
      setSentStatus("none")
      const { email, phone } = resovledContactInfo
      const inputData: { [key: string]: string | undefined } = {
        firstName: mblsReservationClient?.firstName,
        lastName: mblsReservationClient?.lastName,
        email,
        phone,
        tenantId,
      }

      // remove null values
      Object.keys(inputData).forEach(
        (key) =>
          (inputData[key] === null || inputData[key] === "") &&
          delete inputData[key],
      )

      const actionReq = {
        thingTypeMrn: ActionReqThingTypeMrn,
        tenantId,
        idempotencyKey: uuidv4(),
        actionType: ActionReqTypeEnum.SendLockerFullCapacityNotification,
        executionMode: ActionReqExecutionModeEnum.sync,
        inputData,
      }
      await createThings({
        body: { things: [actionReq] },
        params: { tenantId },
      }).unwrap()

      setSentStatus("success")

      clearMblsReservation(false)
    } catch (error: any) {
      console.error(error)

      setSentStatus("error")
    } finally {
      setIsProcessing(false)
    }
  }, [
    clearMblsReservation,
    createThings,
    mblsReservationClient?.firstName,
    mblsReservationClient?.lastName,
    resovledContactInfo,
    tenantId,
  ])

  const unableToSendNotif = useMemo(() => {
    return (
      !generatedPreview?.email && !generatedPreview?.sms && !isFetchingPreview
    )
  }, [generatedPreview?.email, generatedPreview?.sms, isFetchingPreview])

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        bgcolor: "secondary.main",
        color: "primary.main",
        p: 2,
        display: "flex",
        //   alignItems: "center",
        justifyContent: "space-between",
        width: "calc(100vw - 30px)",
        // bottom shadow
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      {/* <Typography variant="body2">{t("lockerReservation")}</Typography> */}

      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="body1" fontWeight={"bold"}>
            {t("creatingNewReservationFor")}
          </Typography>
          <Typography variant="body2">
            {t("creatingNewReservationInstruction")}
          </Typography>
        </Stack>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={8} alignItems="center" pl={2}>
        <FieldDisplay
          label={`👤 ${t("recipient")}`}
          isLoading={!mblsReservationClient?.firstName}
          value={`${mblsReservationClient?.firstName} ${mblsReservationClient?.lastName} `}
        />
        <FieldDisplay
          label={`🗓️ ${t("deliveryDate")}`}
          value={`${
            !!mblsReservation?.deliveryDate
              ? new Date(mblsReservation?.deliveryDate).toLocaleDateString()
              : "-"
          }`}
        />
        {mblsReservation?.timeConstraintStart && (
          <FieldDisplay
            label={`🕝 ${t("timeConstraint")}`}
            //   isLoading={!mblsReservation?.timeConstraintStart}
            value={`${mblsReservation?.timeConstraintStart || "N/A"}  -  ${
              mblsReservation?.timeConstraintEnd || "N/A"
            }`}
          />
        )}
      </Stack>
      <Divider />

      <Stack direction="row" spacing={3} alignItems="center">
        <Typography variant="body2">
          {t("lockerFullCapacityQuestion")}
        </Typography>
        <Sl2Btn
          variant="outlined"
          size="small"
          sx={{
            mt: 1,
          }}
          onClick={() => setShowFullCapacityDeliveryDialog(true)}
          disabled={isProcessing || !mblsReservationClient}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {isProcessing && <CircularProgress size={14} />}

            <Typography variant="body2">
              {t("sendNotifFullCapacity")}
            </Typography>
          </Stack>
        </Sl2Btn>
      </Stack>
      <Dialog open={showFullCapacityDeliveryDialog} maxWidth="lg" fullWidth>
        <DialogTitle>{t("confirmDeliveryToClient")}</DialogTitle>
        <Divider />
        <DialogContent sx={{ minHeight: 200 }}>
          <Stack direction="column" spacing={4}>
            {/* <OsReservationSelectedClient /> */}
            {/* <Paper
              variant="outlined"
              sx={{
                borderLeft: "3px solid #234285",
                borderRadius: 1,
                p: 1,
                display: "none",
              }}
            >
              <Stack direction="column" spacing={1}>
                <FieldDisplayInline
                  isLoading={!mblsReservationClient?.firstName}
                  value={`${mblsReservationClient?.firstName} ${mblsReservationClient?.lastName} `}
                  valueSx={{
                    fontWeight: "bold",
                    color: "primary.main",
                  }}
                />
                <FieldDisplayInline
                  label={
                    <Email
                      fontSize="small"
                      sx={{
                        color: "grey",
                      }}
                    />
                  }
                  isLoading={!mblsReservationClient?.firstName}
                  value={resovledContactInfo.email || "-"}
                />
                <FieldDisplayInline
                  label={
                    <Sms
                      fontSize="small"
                      color="primary"
                      sx={{
                        color: "grey",
                      }}
                    />
                  }
                  isLoading={!mblsReservationClient?.firstName}
                  value={resovledContactInfo.phone || "-"}
                />
              </Stack>
            </Paper> */}
            <Stack direction="row" spacing={4} alignItems="center">
              {(generatedPreview?.email || isFetchingPreview) && (
                <Box
                  sx={{
                    width: "50%",
                    // pr: 1,
                  }}
                >
                  <EmailPreview
                    isFetchingPreview={isFetchingPreview}
                    fullName={resovledContactInfo?.fullName}
                    toEmail={generatedPreview?.email?.toEmail}
                    fromEmail={generatedPreview?.email?.fromEmail}
                    subject={generatedPreview?.email?.subject}
                    body={generatedPreview?.email?.body}
                  />
                </Box>
              )}
              {(generatedPreview?.sms || isFetchingPreview) && (
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <SmsPreview
                    isFetchingPreview={isFetchingPreview}
                    fullName={resovledContactInfo?.fullName}
                    toPhoneNumber={generatedPreview?.sms?.toPhoneNumber}
                    fromPhoneNumber={generatedPreview?.sms?.fromPhoneNumber}
                    message={generatedPreview?.sms?.message}
                  />
                </Box>
              )}
              {/* if neither generatedPreview?.email nor generatedPreview?.ss display error message */}
              {unableToSendNotif && (
                <Alert severity="error">{t("noPreviewAvailable")}</Alert>
              )}
            </Stack>
            {!unableToSendNotif && (
              <DialogContentText justifyContent={"center"}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Typography fontWeight={"bold"}>
                    {t("confirmDeliveryToClientDescription")}
                  </Typography>
                </Stack>
              </DialogContentText>
            )}
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Stack direction="row" spacing={1} alignItems="center">
            {isProcessing && <CircularProgress size={14} />}
            {sentStatus === "success" && (
              <Typography variant="body2" color="success.main">
                {t("notifSent")}
              </Typography>
            )}

            {sentStatus === "error" && (
              <Typography variant="body2" color="error.main">
                {t("notifFailed")}
              </Typography>
            )}
            <Sl2Btn
              onClick={() => setShowFullCapacityDeliveryDialog(false)}
              variant="outlined"
            >
              {t("close")}
            </Sl2Btn>

            {sentStatus === "success" && (
              <Sl2Btn variant="contained" startIcon={<Send />} disabled={true}>
                {t("sent")}
              </Sl2Btn>
            )}
          </Stack>

          {sentStatus === "none" && (
            <Sl2Btn
              onClick={onSendNotifFullCapacity}
              variant="contained"
              startIcon={<Send />}
              disabled={unableToSendNotif}
            >
              {t("send")}
            </Sl2Btn>
          )}
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

const FieldDisplay: FC<{
  label: string
  value: string | JSX.Element
  isLoading?: boolean
}> = ({ label, value, isLoading = false }) => {
  return (
    <Stack direction="column" spacing={0}>
      <Typography variant="body1">{label}</Typography>

      {isLoading ? (
        <CircularProgress
          size="1.5rem"
          sx={{
            color: "primary.main",
            fontSize: "small",
          }}
        />
      ) : (
        <Typography variant="body1" fontWeight="bold" ml={3}>
          {value}
        </Typography>
      )}
    </Stack>
  )
}
