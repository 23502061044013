import { Close, Refresh } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import { map, omit } from "lodash"
import moment from "moment"
import { FC, useCallback, useMemo, useState } from "react"
import { SstReservation } from "shared/reservation"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { useAppSelector } from "src/app/hooks"
import { RootState } from "src/app/store"
import { useGetSstReservationsQuery } from "src/features/partners/partnersApi"
import { selectSstReservations } from "src/features/partners/sstThingsSlice"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectSaList } from "src/features/things/thingsSlice"
import ObjectDisplayTable from "./ObjectDisplayTable"

type SstReservationsByTenantProps = {}

export const SstReservationsByTenant: FC<SstReservationsByTenantProps> = () => {
  const [previewRId, setPreviewLId] = useState<string | null>(null)

  const [searchQuery, setSearchQuery] = useState<string>("")

  const [selectedTenantId, setSelectedTenantId] = useState<string>()

  const qReservations = useGetSstReservationsQuery(
    {
      params: { tenantId: selectedTenantId },
    },
    {
      skip: !selectedTenantId,
    },
  )

  const _sstReservations = useAppSelector(selectSstReservations)

  const sstReservations = useMemo(() => {
    return (
      map(_sstReservations, (r) => omit(r, ["sstType"]))
        .filter(Boolean)
        // sort by created date desc
        .sort((a: any, b: any) => {
          return new Date(b._created).getTime() - new Date(a._created).getTime()
        }) as SstReservation[]
    )
  }, [_sstReservations])

  const previewR = useMemo(() => {
    return sstReservations?.find((r) => r._id === previewRId)
  }, [previewRId, sstReservations])

  const onClick = useCallback((r: any) => {
    setPreviewLId(r?._id)
  }, [])

  const filteredData = useMemo(() => {
    return sstReservations.filter(
      (r) =>
        r._id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (r.number + "").includes(searchQuery.toLowerCase()) ||
        r.tag?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        r.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        r.admin_code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        r.delivery_code?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        r.reception_code?.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  }, [searchQuery, sstReservations])

  const qTenants = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
    },
  })

  const tenants = useAppSelector((s: RootState) =>
    selectSaList<Tenant>(s),
  ).filter((t) => t.thingTypeMrn === TenantThingTypeMrn)

  // tenant selector options
  const tenantOptions = useMemo(() => {
    return tenants
      .sort(
        // alphabetically
        (a, b) => a.name.localeCompare(b.name),
      )
      .map((t) => ({
        value: t.id,
        ...t,
      }))
  }, [tenants])

  if (!sstReservations) {
    // outlined paper with no data message
    return (
      <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
        No data
      </Paper>
    )
  }

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body1" fontWeight="bold">
        {filteredData?.length === sstReservations?.length && (
          <> {`${t("reservations")} (${sstReservations?.length})`}</>
        )}
        {filteredData?.length !== sstReservations?.length && (
          <>
            {" "}
            {`${t("reservations")} (${filteredData?.length}/${
              sstReservations?.length
            })`}
          </>
        )}
      </Typography>
      <Stack direction="column" spacing={3} sx={{}}>
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControl>
            <Typography variant="caption">{t("selectOrg")}</Typography>
            <Select
              label="Tenant"
              variant="outlined"
              sx={{
                width: "500px",
              }}
              size="small"
              value={selectedTenantId}
              onChange={(e) => setSelectedTenantId(e.target.value)}
            >
              {map(tenantOptions, (o) => (
                <MenuItem key={o.value} value={o.value}>
                  <Stack direction="column" spacing={1}>
                    <Typography variant="body1" fontWeight="bold">
                      {o.name}
                    </Typography>
                    <Typography variant="caption">{o.id}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {qReservations.isFetching || qReservations.isLoading ? (
            <CircularProgress />
          ) : (
            <IconButton onClick={qReservations.refetch}>
              <Refresh />
            </IconButton>
          )}
        </Stack>
        {/* info text */}
        <Stack direction="column" spacing={1}>
          <Typography variant="caption" color="textSecondary">
            {t("sstReservationsByTenantInfo")}
          </Typography>
          <TextField
            label="Search"
            variant="outlined"
            sx={{
              width: "500px",
            }}
            size="small"
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Stack>
      </Stack>
      {filteredData?.length === 0 &&
        !(qReservations.isFetching || qReservations.isLoading) && (
          <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
            No data
          </Paper>
        )}
      {filteredData?.length > 0 &&
        filteredData.map((r: SstReservation) => (
          <Stack direction="column" spacing={1} key={r._id}>
            <Paper variant="outlined" elevation={0} sx={{ p: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Chip
                  label={r.tag || "No tag"}
                  color="primary"
                  variant="outlined"
                />
                <Stack direction="column" spacing={1}>
                  <Box
                    sx={{
                      // link style
                      cursor: "pointer",
                      color: "primary.main",
                    }}
                    onClick={() => onClick(r)}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography variant="body1">{r.number}</Typography>
                      <Typography variant="body1">{r.status}</Typography>
                      <Typography variant="body1">{r._id}</Typography>
                    </Stack>
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Typography variant="body2">{`${t("deliveryCode")} ${
                      r.delivery_code
                    }`}</Typography>
                    <Typography variant="body2">{`${t("receptionCode")} ${
                      r.reception_code
                    }`}</Typography>
                    <Typography variant="body2">{`${t("adminCode")} ${
                      r.admin_code
                    }`}</Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Typography variant="caption" color="textSecondary">
                      {`${t("ID")} ${r._id}`}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {`${t("created")} ${moment(r._created).fromNow()}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        ))}
      <Drawer
        anchor="right"
        open={!!previewRId}
        onClose={() => setPreviewLId(null)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80vw",
            maxWidth: "1500px",
            maxHeight: "calc(100vh - 64px)",
            height: "calc(100vh - 64px)",
            paddingTop: "64px",
          },
        }}
      >
        <Stack direction="column" spacing={1} p={2}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{
                  color: "primary.main",
                }}
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography variant="body1" fontWeight="bold">
                  {previewR?.number}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {previewR?.status}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {previewR?._id}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={() => setPreviewLId(null)}>
              <Close />
            </IconButton>
          </Stack>
          <Divider />

          <ObjectDisplayTable data={previewR} />
        </Stack>
      </Drawer>
    </Stack>
  )
}

export default SstReservationsByTenant
