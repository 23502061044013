import { Close } from "@mui/icons-material"
import {
  Alert,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Snackbar,
  Stack,
  Tabs,
  Typography,
} from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { ClientThingTypeMrn } from "shared/clientData"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { TenantThingTypeMrn } from "shared/tenantData"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
} from "src/features/things/thingsApi"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { v4 as uuid } from "uuid"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaLayout from "./SaLayout"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2Tab } from "./Sl2Tab"

const defaultColWidth = 300

export const SaDeleteThingsPage: FC = () => {
  const { t } = useTranslation()

  const [snackMessage, setSnackMessage] = useState({
    message: "",
    severity: "success" as "success" | "error" | "warning" | "info" | undefined,
  })

  const [tab, setTab] = useSearchParamsState("tab", "")

  const q = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetDeletedThings,
        "ap.thingTypeMrn": tab,
      },
    },
    {
      skip: !tab,
    },
  )

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      setTab(newValue + "", true)
      if (!q.isUninitialized) {
        q.refetch()
      }
    },
    [q, setTab],
  )

  const isLoading = q.isLoading || q.isFetching

  const [selectedId, setSelectedId] = useSearchParamsState("selectedId", "")

  const selectedThing = useMemo(() => {
    return q.data?.things.find((t) => t.id === selectedId)
  }, [q.data?.things, selectedId])

  const columns = useCallback(() => {
    const common = [
      {
        field: "id",
        headerName: t("id"),
        width: 300,
      },
      {
        field: "thingTypeMrn",
        headerName: t("thingTypeMrn"),
        width: 150,
      },
      {
        field: "createdAt",
        headerName: t("createdAt"),
        width: 200,
        sortable: false,
        filterable: false,
      },
      {
        field: "updatedAt",
        headerName: t("updatedAt"),
        width: 200,
        sortable: false,
        filterable: false,
      },
    ]

    const lockerColumns: DataGridProps["columns"] = [
      {
        field: "id",
        headerName: t("id"),
        width: 300,
        // rednercell with typog monospace with set selectedId onclick
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                cursor: "pointer",
                fontFamily: "monospace",
                color: "primary.main",
                textDecoration: "underline",
              }}
              onClick={() => setSelectedId(params.row.id as string)}
            >
              {params.value}
            </Typography>
          )
        },
      },

      {
        field: "name",
        headerName: t("name"),
        minWidth: defaultColWidth,
        renderCell: (params) => {
          const l = getLockerDisplayValues(params.row as Locker)
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              {/* <Preview
                sx={{
                  cursor: "pointer",
                  color: "gray",
                }}
                // onClick={() => setSelectedLockerId(params.row.id as string)}
              /> */}
              {/* <Link to={`/sa/lockers/${params.row.id}`}>
              </Link> */}
              <Typography variant="body2" noWrap>
                {params.value || l.i18n.name}
              </Typography>
              {/* {displayWarning && (
                <Tooltip
                  title={<WarningInfo locker={params.row as Locker} />}
                  placement="top-start"
                  arrow
                >
                  <Warning color="warning" fontSize="small" />
                </Tooltip>
              )} */}
              {/* displat 'virtual' in chip if params.row.isVirtual */}
              {params.row.isVirtual && (
                <Chip
                  label="Virtual"
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              )}
            </Stack>
          )
        },
      },
      {
        field: "sstLockerId",
        headerName: t("sstLockerId"),
        minWidth: defaultColWidth * 1.4,
        renderCell: (params) => {
          return (
            <Typography variant="body2" fontFamily={"monospace"}>
              {params.row?.sstLockerId || "-"}
            </Typography>
          )
        },
      },
      {
        field: "tenant",
        headerName: t("tenant"),
        minWidth: defaultColWidth,
        renderCell: (params) => {
          return (
            <Link to={`/sa/tenants/${params.row?.tenantId}`}>
              <Typography variant="body2">
                {params.row?.tenantId || "-"}
              </Typography>
            </Link>
          )
        },
      },
      {
        field: "createdAt",
        headerName: `📅 ${t("createdAt")}`,
        minWidth: defaultColWidth,
      },
      {
        field: "updatedAt",
        headerName: `📅 ${t("updatedAt")}`,
        minWidth: defaultColWidth,
      },
      {
        field: "description",
        headerName: t("description"),
        minWidth: defaultColWidth,
      },
      {
        field: "address1",
        headerName: t("address1"),
        minWidth: defaultColWidth,
      },
      {
        field: "address2",
        headerName: t("address2"),
        minWidth: defaultColWidth,
      },
      {
        field: "city",
        headerName: t("city"),
        minWidth: defaultColWidth,
      },
      {
        field: "province",
        headerName: t("province"),
        minWidth: defaultColWidth,
      },
      {
        field: "country",
        headerName: t("country"),
        minWidth: defaultColWidth,
      },
      {
        field: "postalCode",
        headerName: t("postalCode"),
        minWidth: defaultColWidth,
      },
      {
        field: "latitude",
        headerName: t("latitude"),
        minWidth: defaultColWidth,
      },
      {
        field: "longitude",
        headerName: t("longitude"),
        minWidth: defaultColWidth,
      },
      {
        field: "lockerLocation",
        headerName: t("lockerLocation"),
        minWidth: defaultColWidth,
      },
      {
        field: "displayOrder",
        headerName: t("displayOrder"),
        minWidth: defaultColWidth,
      },
    ]

    const tenantColumns: DataGridProps["columns"] = [
      {
        field: "id",
        headerName: t("id"),
        width: 300,
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                cursor: "pointer",
                fontFamily: "monospace",
                color: "primary.main",
                textDecoration: "underline",
              }}
              onClick={() => setSelectedId(params.row.id as string)}
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: "name",
        headerName: t("name"),
        minWidth: defaultColWidth,
      },
      // mblsProviderId
      {
        field: "mblsProviderId",
        headerName: t("mblsProviderId"),
        minWidth: defaultColWidth,
      },
      {
        field: "partnerName",
        headerName: t("partnerName"),
        minWidth: defaultColWidth,
      },
      {
        field: "contactName",
        headerName: t("contactName"),
        minWidth: defaultColWidth,
      },
      {
        field: "contactEmail",
        headerName: t("contactEmail"),
        minWidth: defaultColWidth,
      },
      {
        field: "phone",
        headerName: t("phone"),
        minWidth: defaultColWidth,
      },
      {
        field: "address",
        headerName: t("address"),
        minWidth: defaultColWidth,
      },
      {
        field: "city",
        headerName: t("city"),
        minWidth: defaultColWidth,
      },
      {
        field: "state",
        headerName: t("state"),
        minWidth: defaultColWidth,
      },
      {
        field: "country",
        headerName: t("country"),
        minWidth: defaultColWidth,
      },
      {
        field: "postalCode",
        headerName: t("postalCode"),
        minWidth: defaultColWidth,
      },
      {
        field: "website",
        headerName: t("website"),
        minWidth: defaultColWidth,
      },
      {
        field: "logoUrl",
        headerName: t("logoUrl"),
        minWidth: defaultColWidth,
      },
      {
        field: "status",
        headerName: t("status"),
        minWidth: defaultColWidth,
      },
    ]

    const clientColumns: DataGridProps["columns"] = [
      {
        field: "id",
        headerName: t("id"),
        width: 300,
        renderCell: (params) => {
          return (
            <Typography
              variant="body2"
              sx={{
                cursor: "pointer",
                fontFamily: "monospace",
                color: "primary.main",
                textDecoration: "underline",
              }}
              onClick={() => setSelectedId(params.row.id as string)}
            >
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: "tenantId",
        headerName: t("tenantId"),
        minWidth: defaultColWidth,
      },
      {
        field: "firstName",
        headerName: t("firstName"),
        minWidth: defaultColWidth,
      },
      {
        field: "lastName",
        headerName: t("lastName"),
        minWidth: defaultColWidth,
      },
      // mblsProviderId
      {
        field: "language",
        headerName: t("language"),
        minWidth: defaultColWidth,
      },
      {
        field: "email",
        headerName: t("email"),
        minWidth: defaultColWidth,
      },
      {
        field: "phone",
        headerName: t("phone"),
        minWidth: defaultColWidth,
      },
    ]

    return tab === LockerThingTypeMrn
      ? lockerColumns
      : tab === TenantThingTypeMrn
      ? tenantColumns
      : clientColumns
  }, [setSelectedId, t, tab])

  const [isConfirmingRevertDelete, setIsConfirmingRevertDelete] =
    useState(false)

  const [isRevertingDelete, setIsRevertingDelete] = useState(false)

  const [revertError, setRevertError] = useState<string | null>(null)

  const handleRevertDeleteConfirm = useCallback(() => {
    setIsConfirmingRevertDelete(true)
  }, [])

  const [createThings] = useCreateThingsMutation()

  const revertAction = useCallback(async () => {
    try {
      setRevertError(null)
      setIsRevertingDelete(true)
      const tenantId =
        selectedThing.thingTypeMrn === TenantThingTypeMrn
          ? selectedThing.id
          : selectedThing.tenantId
      const action = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        actionType: ActionReqTypeEnum.RevertDeleteThing,
        idempotencyKey: uuid(),
        tenantId,
        inputData: {
          tenantId,
          id: selectedThing?.id,
          thingTypeMrn: selectedThing?.thingTypeMrn,
        },
      }
      const res = await createThings({
        body: { things: [action] },
        params: { tenantId },
      }).unwrap()

      if (res.things?.[0].errors) {
        throw new Error(res.things[0].errors[0].message)
      }
    } catch (error: any) {
      setRevertError(error.message)
    } finally {
      setIsRevertingDelete(false)
      setIsConfirmingRevertDelete(false)
      setSelectedId("")
      q.refetch()
    }
  }, [
    selectedThing?.thingTypeMrn,
    selectedThing?.id,
    selectedThing?.tenantId,
    createThings,
    setSelectedId,
    q,
  ])

  return (
    <SaLayout selectedMenuItemLabelKey="deletedThings">
      <Stack direction="column" spacing={1} sx={{ p: 2, mt: 4 }}>
        <Typography variant="h5">{t("deleteThingsTitle")}</Typography>
        <Typography variant="body1">{t("deleteThingsDescription")}</Typography>
        <Divider />
        <Stack
          direction="row"
          spacing={1}
          height="100%"
          alignItems={"flex-start"}
          justifyContent={"stretch"}
          sx={{ pt: 3 }}
        >
          <Stack
            direction="column"
            spacing={1}
            // alignItems={"flex-end"}
            height={"100%"}
          >
            <Typography variant="body1" noWrap>
              {t("thingTypes")}
            </Typography>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tab}
              onChange={handleTabChange}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                height: "100%",
                width: "200px",
                alignContent: "flex-end",

                // alight right
                // textAlign: "right",
                // justifyContent: "flex-end",
              }}
            >
              <Sl2Tab
                label={t("lockers")}
                sx={{
                  alignItems: "flex-end",
                }}
                value={LockerThingTypeMrn}
              />
              <Sl2Tab
                label={t("organizations")}
                sx={{
                  alignItems: "flex-end",
                }}
                value={TenantThingTypeMrn}
              />
              <Sl2Tab
                label={t("clients")}
                sx={{
                  alignItems: "flex-end",
                }}
                value={ClientThingTypeMrn}
              />
            </Tabs>
          </Stack>

          {/* {
            {
              sms: <SaNotifDeliveriesSms />,
              email: <SaNotifDeliveriesEmail />,
              emailEvents: <SaNotifDeliveriesEmailEvents />,
            }[TabMapReverse[Number(tab)]]
          } */}
          <Stack direction="column" spacing={1}>
            {tab && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body1" fontWeight="bold">
                  {q.data?.things.length}{" "}
                  {t(
                    tab === TenantThingTypeMrn
                      ? "deletedTenants"
                      : tab === ClientThingTypeMrn
                      ? "deletedClients"
                      : "deletedLockers",
                  )}
                </Typography>
              </Stack>
            )}
            <DataGrid
              rows={q.data?.things || []}
              columns={columns()}
              loading={isLoading}
              autoHeight
            />
          </Stack>
        </Stack>
      </Stack>

      <Snackbar
        open={!!snackMessage?.message}
        autoHideDuration={6000}
        onClose={() => setSnackMessage({ message: "", severity: undefined })}
      >
        <Alert severity={snackMessage.severity}>{snackMessage.message}</Alert>
      </Snackbar>
      {selectedId && (
        <Drawer
          anchor="right"
          open={!!selectedId}
          onClose={() => setSelectedId("")}
          sx={{
            "& .MuiDrawer-paper": {
              width: "80vw",
              maxWidth: "1500px",
              maxHeight: "calc(100vh - 64px)",
              height: "calc(100vh - 64px)",
              paddingTop: "64px",
            },
          }}
        >
          <Stack direction="column" spacing={1} p={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    color: "primary.main",
                  }}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {getLockerDisplayValues(selectedThing).name}
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedThing?.id}
                  </Typography>
                </Stack>
              </Stack>
              <IconButton onClick={() => setSelectedId("")}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />
            <Stack direction="row" spacing={3} alignItems="center">
              <Typography variant="body1" fontWeight="bold">
                {t("revertDelete")}
              </Typography>
              <Alert severity="warning">{t("revertDeleteWarning")}</Alert>
              <Sl2Btn
                variant="outlined"
                color="warning"
                onClick={handleRevertDeleteConfirm}
              >
                {t("revertDelete")}
              </Sl2Btn>
              <Dialog
                open={isConfirmingRevertDelete}
                onClose={() => setIsConfirmingRevertDelete(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>{t("revertDelete")}</DialogTitle>
                <DialogContent>
                  <Typography>{t("revertDeleteConfirm")}</Typography>
                </DialogContent>
                <DialogActions>
                  <Sl2Btn onClick={() => setIsConfirmingRevertDelete(false)}>
                    {t("cancel")}
                  </Sl2Btn>
                  <Sl2Btn
                    onClick={revertAction}
                    color="warning"
                    disabled={isRevertingDelete}
                    variant="contained"
                  >
                    {t("revertDelete")}
                  </Sl2Btn>
                </DialogActions>
              </Dialog>
            </Stack>

            <Divider />
            <ObjectDisplayTable data={selectedThing} />
          </Stack>
        </Drawer>
      )}
    </SaLayout>
  )
}

export default SaDeleteThingsPage
