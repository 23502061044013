import { Refresh } from "@mui/icons-material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material"

import { t } from "i18next"
import moment from "moment"
import { FC, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { ActionReq } from "shared/actionReq"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { useAppSelector } from "src/app/hooks"
import { RootState } from "src/app/store"
import { selectSaThingById } from "src/features/things/thingsSlice"
import { v4 as uuid } from "uuid"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
  useUpdateThingsMutation,
} from "../features/things/thingsApi"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaLayout from "./SaLayout"
import SaOrgForm from "./SaOrgForm"
import { Sl2Btn } from "./Sl2Btn"
import { UnstyledLink } from "./UnstyledLink"

export const SaTenantPage: FC = () => {
  const { t } = useTranslation()

  //  get tenantId from path p arams
  const { tenantId } = useParams<{ tenantId: string }>()

  const tenant = useAppSelector((s: RootState) =>
    selectSaThingById(s, tenantId || ""),
  ) as Tenant | undefined

  const { isLoading, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
      "ap.ids[0]": tenantId,
    },
  })

  const [createThings] = useCreateThingsMutation()

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)

  const [deleteError, setDeleteError] = useState<string | null>(null)

  const deleteAction = useCallback(async () => {
    try {
      setIsDeleting(true)
      setDeleteError(null)

      const action = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        actionType: ActionReqTypeEnum.DeleteThing,
        idempotencyKey: uuid(),

        tenantId,
        inputData: {
          tenantId,
          id: tenantId,
          thingTypeMrn: TenantThingTypeMrn,
        },
      }
      const res = await createThings({
        body: { things: [action] },
        params: { tenantId },
      }).unwrap()
      const thing = res.things?.[0]
      if (!thing || thing?.status === "failure") {
        throw new Error("unable to delete tenant")
      }
    } catch (error: any) {
      setDeleteError(error.message)
      throw error
    } finally {
      setIsDeleting(false)
    }
  }, [createThings, tenantId])

  const navigate = useNavigate()

  return (
    <SaLayout selectedMenuItemLabelKey="organizations">
      <Stack direction="column" spacing={3} p={2} pl={4}>
        <Header tenant={tenant} isLoading={isLoading} refetch={refetch} />
        {tenant?.id && (
          <Stack
            direction="row"
            spacing={4}
            alignItems="flex-start"
            justifyContent="stretch"
          >
            <SaOrgForm tenant={tenant} />
            <SaTenantDetails tenant={tenant} onRefetch={refetch} />
          </Stack>
        )}
        {tenant && (
          <Stack direction="column" spacing={1}>
            <Typography variant="body1">{t("details")}</Typography>
            <ObjectDisplayTable data={tenant} />
          </Stack>
        )}
        <Divider />
        <Stack direction="column" spacing={1} width="700px">
          {/* delete locker */}
          <Typography variant="body1" fontWeight="bold">
            {t("tenantDelete")}
          </Typography>
          <Alert severity="error">{t("tenantDeleteWarning")}</Alert>
          <Box>
            <Sl2Btn
              variant="contained"
              color="error"
              onClick={() => setIsConfirmingDelete(true)}
              disabled={isDeleting}
            >
              {t("delete")}
            </Sl2Btn>
          </Box>
          <Dialog
            open={isConfirmingDelete}
            onClose={() => setIsConfirmingDelete(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>{t("confirmDelete")}</DialogTitle>
            <DialogContent>
              <Stack direction="column" spacing={1}>
                <DialogContentText>
                  {t("confirmDeleteTenantWarning")}
                </DialogContentText>
                {deleteError && (
                  <DialogContentText color={"error"}>
                    {deleteError}
                  </DialogContentText>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Sl2Btn
                onClick={() => setIsConfirmingDelete(false)}
                disabled={isDeleting}
              >
                {t("cancel")}
              </Sl2Btn>
              <Sl2Btn
                onClick={async () => {
                  await deleteAction()
                  setIsConfirmingDelete(false)
                  // navigate to tenants
                  window.location.href = "/sa/tenants"
                  // navigate("/sa/tenants", {
                  //   replace: true,
                  // })
                }}
                variant="contained"
                color="error"
                disabled={isDeleting}
              >
                {t("delete")}
              </Sl2Btn>
            </DialogActions>
          </Dialog>
        </Stack>
        <Divider />
        {tenant && <Footer tenant={tenant} />}
      </Stack>
    </SaLayout>
  )
}

export default SaTenantPage

const Header: FC<{
  tenant?: Tenant
  isLoading: boolean
  refetch: () => void
}> = ({ tenant, isLoading, refetch }) => {
  const { t } = useTranslation()
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <UnstyledLink to="/sa/tenants">
          <Typography variant="h6">{t("organizations")}</Typography>
        </UnstyledLink>
        <ChevronRightIcon />
        {tenant?.id && (
          <Typography variant="h6" fontWeight={"bold"}>
            {tenant.name}
          </Typography>
        )}
        {isLoading && <CircularProgress size={20} />}
      </Stack>
      <IconButton onClick={refetch}>
        <Refresh />
      </IconButton>
    </Stack>
  )
}

const Footer: FC<{ tenant: Tenant }> = ({ tenant }) => {
  const { t } = useTranslation()
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption">{t("createdAt")}</Typography>
        <Typography variant="caption">
          {moment(tenant.createdAt).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
        <Typography variant="caption">-</Typography>
        <Typography variant="caption">{t("updatedAt")}</Typography>

        <Typography variant="caption">
          {moment(tenant.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
        </Typography>
      </Stack>
      <Typography variant="caption">{tenant.id}</Typography>
    </Stack>
  )
}

const SaTenantDetails: FC<{
  tenant: Tenant
  onRefetch: () => void
}> = ({ tenant, onRefetch }) => {
  const tenantId = tenant.id

  const [isReadOnly, _setIsReadOnly] = useState(
    tenant?.notifTemplateReadOnly || false,
  )

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const [showPresetConfirmationDialog, setShowPresetConfirmationDialog] =
    useState(false)
  const [presetNameToApply, setPresetNameToApply] = useState<string | null>(
    null,
  )
  // error state
  const [error, setError] = useState<string | null>(null)

  const showReadOnlyDialog = useCallback(() => {
    setError(null)
    setShowConfirmationDialog(true)
  }, [setShowConfirmationDialog])

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()

  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const setIsReadOnly = useCallback(
    async (isReadOnly: boolean) => {
      try {
        await updateThings({
          body: {
            things: [
              {
                id: tenant?.id,
                thingTypeMrn: tenant?.thingTypeMrn,
                notifTemplateReadOnly: isReadOnly,
              },
            ],
          },
          params: { tenantId: tenant?.id },
        }).unwrap()
        _setIsReadOnly(isReadOnly)
      } catch (e: any) {
        setError(e.message)
      }
    },

    [tenant?.id, tenant?.thingTypeMrn, updateThings],
  )
  const applyPresetClicked = useCallback(
    (presetName: string) => {
      setError(null)
      setPresetNameToApply(presetName)
      setShowPresetConfirmationDialog(true)
    },
    [setShowPresetConfirmationDialog],
  )
  const [isApplyingPreset, setIsApplyingPreset] = useState(false)

  const applyPreset = useCallback(async () => {
    setIsApplyingPreset(true)
    try {
      const toCreate: Partial<ActionReq> = {
        thingTypeMrn: ActionReqThingTypeMrn,
        executionMode: ActionReqExecutionModeEnum.sync,
        tenantId,
        idempotencyKey: uuid(),
        actionType: ActionReqTypeEnum.ApplyTemplatePreset,
        inputData: {
          tenantId,
          presetName: presetNameToApply,
        },
      }

      await createThings({
        body: { things: [toCreate] },
        params: { tenantId },
      }).unwrap()
      onRefetch()
      setPresetNameToApply(null)
    } catch (e: any) {
      setError(e.message)
    } finally {
      setIsApplyingPreset(false)
    }
  }, [createThings, onRefetch, presetNameToApply, tenantId])

  return (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <Stack direction="column" spacing={3} minWidth={500}>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            {t("templates")}
          </Typography>
          <Divider />
        </Box>
        <Alert severity="info" variant="standard">
          {t("templatesDescriptionSuperAdmin")}
        </Alert>
        {/* <Box sx={{ pt: 1 }} /> */}
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            {t("notifTemplateReadOnly")}
          </Typography>
        </Stack>
        <Box>
          <Switch
            checked={isReadOnly}
            onChange={(event) => {
              showReadOnlyDialog()
            }}
          />
        </Box>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            {t("applyTemplatePreset")}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Sl2Btn
              onClick={() => applyPresetClicked("Default")}
              variant="contained"
              fullWidth
              disabled={isApplyingPreset}
            >
              Default
            </Sl2Btn>
            <Sl2Btn
              onClick={() => applyPresetClicked("JeanCoutu")}
              variant="contained"
              fullWidth
              disabled={isApplyingPreset}
            >
              Jean Coutu
            </Sl2Btn>
            <Sl2Btn
              onClick={() => applyPresetClicked("Brunet")}
              variant="contained"
              fullWidth
              disabled={isApplyingPreset}
            >
              Brunet
            </Sl2Btn>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            {t("appliedTemplatePreset")}
          </Typography>
          {isApplyingPreset ? (
            <CircularProgress size={16} />
          ) : (
            <Typography variant="body2" fontFamily={"monospace"}>
              {tenant.appliedTemplatePreset || "-"}
            </Typography>
          )}
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="body2" fontWeight={"bold"}>
            {t("appliedTemplatePresetDate")}
          </Typography>
          {isApplyingPreset ? (
            <CircularProgress size={16} />
          ) : (
            <Typography variant="body2">
              {tenant.appliedTemplatePresetDate
                ? moment(tenant.appliedTemplatePresetDate).format(
                    "YYYY-MM-DD HH:mm:ss",
                  )
                : "-"}
            </Typography>
          )}
        </Stack>
        <Dialog open={showConfirmationDialog}>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              minWidth: 300,
              p: 2,
            }}
          >
            <Typography variant="h6">{t("notifTemplateReadOnly")}</Typography>
            <Divider />
            <Typography variant="body2">
              {t("notifTemplateReadOnlyDescription")}
            </Typography>
            <Box
              sx={{
                py: 4,
              }}
            >
              <Typography variant="body1" fontWeight={"bold"}>
                {t(
                  isReadOnly
                    ? "confirmDesactivateReadOnly"
                    : "confirmActivdateReadOnly",
                )}
              </Typography>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Sl2Btn
                variant="contained"
                onClick={() => {
                  setIsReadOnly(!isReadOnly)
                  setShowConfirmationDialog(false)
                }}
              >
                {t("confirm")}
              </Sl2Btn>
              <Sl2Btn
                variant="outlined"
                onClick={() => {
                  setShowConfirmationDialog(false)
                }}
              >
                {t("cancel")}
              </Sl2Btn>
            </Stack>
          </Stack>
        </Dialog>
        <Dialog open={showPresetConfirmationDialog}>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              minWidth: 300,
              p: 2,
            }}
          >
            <Typography variant="h6">{t("applyTemplatePreset")}</Typography>
            <Divider />
            <Alert severity="warning">
              {t("applyTemplatePresetDescriptionWarning")}
            </Alert>
            <Box
              sx={{
                py: 4,
              }}
            >
              <Typography variant="body2">
                {t("applyTemplatePresetDescription")}
              </Typography>
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Sl2Btn
                variant="contained"
                onClick={() => {
                  applyPreset()

                  setShowPresetConfirmationDialog(false)
                }}
              >
                {t("confirm")}
              </Sl2Btn>
              <Sl2Btn
                variant="outlined"
                onClick={() => {
                  setPresetNameToApply(null)
                  setShowPresetConfirmationDialog(false)
                }}
              >
                {t("cancel")}
              </Sl2Btn>
            </Stack>
          </Stack>
        </Dialog>
      </Stack>
    </Paper>
  )
}
