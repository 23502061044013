import { Paper, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Reservation } from "shared/reservation"
import { useAppSelector } from "src/app/hooks"
import { MblsConnectorClient } from "src/features/mblsConnector/mblsConnector"
import { selectTenantId } from "src/features/me/meSlice"
import { selectClientById } from "src/features/things/thingsSlice"
import { OsReservationClientMblsSelectorItem } from "./OsReservationClientMblsSelectorItem"

export type OsLockerReservationInfoProps = {
  reservation: Reservation
}

export const OsLockerReservationInfo: FC<OsLockerReservationInfoProps> = ({
  reservation,
}) => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const attrs = [
    {
      label: "firstName",
      value: reservation.firstName,
    },
    {
      label: "lastName",
      value: reservation.lastName,
    },
    {
      label: "email",
      value: reservation.email,
    },
    {
      label: "phone",
      value: reservation.phone,
    },
    {
      label: "status",
      value: reservation.sstReservation?.status,
    },
    {
      label: "createdAt",
      value: reservation.createdAt,
    },
    {
      label: "adminCode",
      value: reservation.sstReservation?.admin_code,
    },
    {
      label: "deliveryCode",
      value: reservation.sstReservation?.delivery_code,
    },
    {
      label: "receptionCode",
      value: reservation.sstReservation?.reception_code,
    },
    {
      label: "id",
      value: reservation.id,
    },
    {
      label: "sst-id",
      value: reservation.sstReservation?._id,
    },
  ]
  const client = useAppSelector((s) =>
    selectClientById(s, reservation?.clientId || ""),
  )
  if (!client) {
    return (
      <Paper
        sx={{ p: 2, color: "primary.main" }}
        variant="elevation"
        elevation={3}
      >
        <Stack direction="column" spacing={0.5}>
          <Typography variant="body2" fontWeight="bold">
            {`${reservation.firstName} ${reservation.lastName}`}
          </Typography>
          {!reservation.firstName && !reservation.lastName && (
            <Typography variant="body1" fontWeight={"bold"}>
              {t("client")} {reservation.clientId?.slice(-10)}
            </Typography>
          )}
          {reservation?.email && (
            <Typography variant="body2">{`${reservation.email}`}</Typography>
          )}
          {reservation?.phone && (
            <Typography variant="body2">{`${reservation.phone}`}</Typography>
          )}
        </Stack>
      </Paper>
    )
  }
  return (
    <OsReservationClientMblsSelectorItem
      client={client as unknown as MblsConnectorClient}
      highlightedPhone={reservation?.phone}
      highlightedEmail={reservation?.email}
    />
  )
}

export default OsLockerReservationInfo
