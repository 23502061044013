import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ClientThingTypeMrn } from "shared/clientData"
import { useCreateThingsMutation } from "src/features/things/thingsApi"
import { useCurrentTenant } from "src/hooks/useCurrentTenant"
import FormFields, { FormField } from "./FormFields"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

type OsCreateSl2ClientDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}
const formJoiSchema = Joi.object({
  lastName: Joi.string().optional().min(1).max(1000).messages({
    "string.empty": "Last name is required",
  }),
  firstName: Joi.string().optional().min(1).max(1000).messages({
    "string.empty": "First name is required",
  }),
  phone: Joi.string()
    .optional()
    .pattern(
      new RegExp(
        /^[(]{1,1}[0-9]{3,3}[)]{1,1}[ ]{1,1}[0-9]{3,3}[-]{1,1}[0-9]{4,4}$/,
      ),
    )
    .message("Phone number must be valid format : (123) 123-1234"),
  notes: Joi.string().optional().min(1).max(5000).allow(""),
  language: Joi.string().required().min(1).max(1000).valid("fr", "en"),
  email: Joi.string()
    .optional()
    .min(1)
    .max(1000)
    .email({ tlds: { allow: false } })
    .allow("")
    .messages({
      "string.email": "Email must be valid format",
    }),
}).or("phone", "email")

export const OsCreateSl2ClientDialog: FC<OsCreateSl2ClientDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation()

  const [error, setError] = useState(false)

  const { fields0, fields1 } = useMemo(() => {
    const fields0: FormField[] = [
      {
        name: "lastName",
        label: t("lastName"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12 },
      },
      {
        name: "firstName",
        label: t("firstName"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12 },
      },
      {
        name: "email",
        label: t("email"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12 },
      },
      {
        name: "phone",
        label: t("phone"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12 },
        inputMask: "(999) 999-9999",
      },
    ]
    const fields1: FormField[] = [
      {
        name: "notes",
        label: t("notes"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 12 },
        InputProps: { multiline: true, rows: 5 },
      },
      {
        name: "language",
        label: t("language"),
        fieldType: "select",
        required: false,
        size: "small",
        fullWidth: true,
        options: [
          { id: "fr", label: t("french") },
          { id: "en", label: t("english") },
        ],
        gridProps: { xs: 12, sm: 6 },
      },
    ]
    return { fields0, fields1 }
  }, [t])

  const [isLoading, setIsLoading] = useState(false)

  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const { tenant } = useCurrentTenant()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <DialogTitle id="alert-dialog-title">{t("createClient")}</DialogTitle>
        {isLoading && <CircularProgress size={20} />}
      </Stack>
      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={1}>
          <Box maxWidth={600}>
            <Sl2FormContainer
              joiSchema={formJoiSchema}
              onSuccess={async (values) => {
                setError(false)
                setIsLoading(true)
                // for phoneNumber, remove () and - and space
                const phoneNumber = values?.phone.replace(/[\s()-]/g, "")
                // console.log("arcCandi", arcCandi)
                const client = {
                  ...values,
                  phone: phoneNumber,
                  thingTypeMrn: ClientThingTypeMrn,
                  tenantId: tenant?.id,
                }
                try {
                  console.log("client", client)
                  await createThings({
                    body: { things: [client] },
                    params: { tenantId: tenant?.id },
                  }).unwrap()
                  onSuccess()
                } catch (e: any) {
                  console.error(e)
                  setError(true)
                } finally {
                  setIsLoading(false)
                }
              }}
            >
              <FormFields fields={fields0} />
              <Box pt={2} />
              <Divider />
              <Box pt={2} />
              <FormFields fields={fields1} />
              <DialogActions>
                <Sl2Btn onClick={onClose} color="primary" disabled={isLoading}>
                  {t("cancel")}
                </Sl2Btn>
                <Sl2Btn
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={16} /> : t("create")}
                </Sl2Btn>
              </DialogActions>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="flex-end"
              >
                {error && (
                  <Typography variant="caption" color="error">
                    {t("unableToCreateClient")}
                  </Typography>
                )}
              </Stack>
            </Sl2FormContainer>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
