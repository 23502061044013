import {
  Alert,
  Divider,
  Snackbar,
  Stack,
  Tabs,
  Typography,
} from "@mui/material"
import { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "src/app/hooks"
import { thingsActions } from "src/features/things/thingsSlice"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import SaLayout from "./SaLayout"
import SaNotifDeliveriesEmail from "./SaNotifDeliveriesEmail"
import SaNotifDeliveriesEmailEvents from "./SaNotifDeliveriesEmailEvents"
import SaNotifDeliveriesSms from "./SaNotifDeliveriesSms"
import { Sl2Tab } from "./Sl2Tab"

const TabMap: Record<string, number> = {
  sms: 0,
  email: 1,
  emailEvents: 2,
}

const TabMapReverse: Record<number, string> = {
  0: "sms",
  1: "email",
  2: "emailEvents",
}

export const SaNotifDeliveriesPage: FC = () => {
  const { t } = useTranslation()

  const [snackMessage, setSnackMessage] = useState({
    message: "",
    severity: "success" as "success" | "error" | "warning" | "info" | undefined,
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(thingsActions.clearSaList())
  }, [dispatch])

  const [tab, setTab] = useSearchParamsState("tab", TabMap.sms + "")

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue + "", true)
      dispatch(thingsActions.clearSaList())
    },
    [dispatch, setTab],
  )

  return (
    <SaLayout selectedMenuItemLabelKey="notifDeliveries">
      <Stack direction="column" spacing={1} sx={{ p: 2, mt: 4 }}>
        <Typography variant="h5">{t("notifDeliveries")}</Typography>
        <Typography variant="body1">
          {t("notifDeliveriesDescription")}
        </Typography>
        <Divider />
        <Stack
          direction="row"
          spacing={1}
          height="100%"
          alignItems={"flex-start"}
          justifyContent={"stretch"}
          sx={{ pt: 3 }}
        >
          <Stack
            direction="column"
            spacing={1}
            // alignItems={"flex-end"}
            height={"100%"}
          >
            <Typography variant="body1" noWrap>
              {t("notifType")}
            </Typography>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={Number(tab)}
              onChange={handleTabChange}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                height: "100%",
                width: "200px",
                alignContent: "flex-end",

                // alight right
                // textAlign: "right",
                // justifyContent: "flex-end",
              }}
            >
              <Sl2Tab
                label={t("sms")}
                sx={{
                  alignItems: "flex-end",
                }}
              />
              <Sl2Tab
                label={t("email")}
                sx={{
                  alignItems: "flex-end",
                }}
              />
              <Sl2Tab
                label={t("emailEvents2")}
                sx={{
                  alignItems: "flex-end",
                }}
              />
            </Tabs>
          </Stack>

          {
            {
              sms: <SaNotifDeliveriesSms />,
              email: <SaNotifDeliveriesEmail />,
              emailEvents: <SaNotifDeliveriesEmailEvents />,
            }[TabMapReverse[Number(tab)]]
          }
        </Stack>
      </Stack>

      <Snackbar
        open={!!snackMessage?.message}
        autoHideDuration={6000}
        onClose={() => setSnackMessage({ message: "", severity: undefined })}
      >
        <Alert severity={snackMessage.severity}>{snackMessage.message}</Alert>
      </Snackbar>
    </SaLayout>
  )
}

export default SaNotifDeliveriesPage
