import { Close, Refresh } from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { get } from "lodash"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EmailDelivery } from "shared/emailDelivery"
import { EmailDeliveryEvent } from "shared/emailDeliveryEvent"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { selectSaList, thingsActions } from "src/features/things/thingsSlice"
import { useSearchParamsState } from "src/hooks/useSearchParamsState"
import { useGetThingsQuery } from "../features/things/thingsApi"
import ObjectDisplayTable from "./ObjectDisplayTable"
import SaNotifDeliveriesEmailDrawer from "./SaNotifDeliveriesEmailDrawer"
import Sl2DataGrid from "./Sl2DataGrid"

const listOfEmailEventType = [
  "Bounce",
  "Complaint",
  "Delivery",
  "Send",
  "Reject",
  "Open",
  "Click",
  "RenderingFailure",
  "DeliveryDelay",
  "Subscription",
]

const defaultColWidth = 230

type SaNotifDeliveriesEmailEventsProps = {}

export const SaNotifDeliveriesEmailEvents: FC<
  SaNotifDeliveriesEmailEventsProps
> = () => {
  const { t } = useTranslation()

  const [eventType, _setEventType] = useSearchParamsState("eventType", "")

  const dispatch = useAppDispatch()

  const setEventType = useCallback(
    (val: string) => {
      dispatch(thingsActions.clearSaList())

      _setEventType(val)
      setParams((prev) => {
        return {
          ...prev,
          "ap.eventType": val,
          "ap.nextToken": undefined,
          timestamp: Date.now(),
        }
      })
    },
    [dispatch, _setEventType],
  )

  const [params, setParams] = useState<Record<string, any>>({
    "ap.name": SharedAccessPatternSaEnum.SaGetEmailDeliveriesByEventType,
    "ap.eventType": eventType,
    "ap.limit": 25,
    "ap.nextToken": undefined,
    timestamp: Date.now(),
  })

  const q = useGetThingsQuery({ params }, { skip: !eventType })

  const emailDeliveryEvents = useAppSelector((state) =>
    selectSaList<EmailDeliveryEvent>(state),
  )

  const [selectedDeliveryEventId, setSelectedDeliveryEventId] =
    useSearchParamsState("selectedDeliveryEventId", "")

  const selectedDeliveryEvent = useMemo(
    () =>
      emailDeliveryEvents.find(
        (d) => d.id === selectedDeliveryEventId,
      ) as EmailDeliveryEvent,
    [emailDeliveryEvents, selectedDeliveryEventId],
  )

  const [selectedDeliveryId, setSelectedDeliveryId] = useSearchParamsState(
    "selectedDeliveryId",
    "",
  )
  const qEmailDelivery = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternSaEnum.SaGetEmailDeliverybyId,
        "ap.id": selectedDeliveryId,
      },
    },
    { skip: !selectedDeliveryId },
  )

  const selectedDelivery = useMemo(
    () =>
      (qEmailDelivery?.data?.things || []).find(
        (d) => d.id === selectedDeliveryId,
      ) as EmailDelivery,
    [qEmailDelivery?.data?.things, selectedDeliveryId],
  )

  const columns = useCallback(
    (t: TFunction): DataGridProps["columns"] =>
      [
        {
          field: "id",
          headerName: t("id"),
          minWidth: 250,
          renderCell: (params) => {
            return (
              <Typography
                variant="caption"
                fontFamily={"monospace"}
                onClick={() =>
                  setSelectedDeliveryEventId(params.value as string)
                }
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {params.value}
              </Typography>
            )
          },
        },
        // emailDeliveryId, with clickable link
        {
          field: "emailDeliveryId",
          headerName: t("emailDeliveryId"),
          minWidth: 300,
          renderCell: (params) => {
            return (
              <Typography
                variant="caption"
                fontFamily={"monospace"}
                onClick={() => setSelectedDeliveryId(params.value as string)}
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {selectedDeliveryId === params.value &&
                (qEmailDelivery?.isFetching || qEmailDelivery?.isLoading) ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="caption">{params.value}</Typography>
                )}
              </Typography>
            )
          },
        },

        {
          field: "source",
          headerName: t("source"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Typography variant="body2">
                {get(params, "row.detail.mail.source")}
              </Typography>
            )
          },
        },
        // destination
        {
          field: "destination",
          headerName: t("destination"),
          minWidth: defaultColWidth,
          renderCell: (params) => {
            return (
              <Typography variant="body2">
                {get(params, "row.detail.mail.destination")}
              </Typography>
            )
          },
        },
        // createdAt
        {
          field: "createdAt",
          headerName: t("createdAt"),
          minWidth: defaultColWidth,
        },
        // eventType
        {
          field: "eventType",
          headerName: t("eventType"),
          minWidth: defaultColWidth,
        },
      ] as DataGridProps["columns"],
    [
      qEmailDelivery?.isFetching,
      qEmailDelivery?.isLoading,
      selectedDeliveryId,
      setSelectedDeliveryEventId,
      setSelectedDeliveryId,
    ],
  )

  return (
    <Box>
      <Stack direction="column" spacing={4} p={2}>
        <Stack
          direction="column"
          spacing={1}
          // alignItems="center"
          // justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <Typography variant="h6">{`${t("emailEvents", {
              count: emailDeliveryEvents?.length,
            })} `}</Typography>
            {!(q.isLoading || q.isFetching) && (
              <IconButton onClick={q.refetch}>
                <Refresh />
              </IconButton>
            )}
            {(q.isLoading || q.isFetching) && <CircularProgress />}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxWidth: 1000,
          }}
        >
          {listOfEmailEventType.map((et) => (
            // Chip outlined if selected
            <Chip
              key={et}
              label={et}
              variant={et === eventType ? "filled" : "outlined"}
              onClick={() => setEventType(et)}
            />
          ))}
        </Stack>
        <Stack direction="column" spacing={2} mt={2}>
          <Box sx={{}}>
            <Sl2DataGrid
              datagridProps={{
                rows: emailDeliveryEvents,
                columns: columns(t),
              }}
              isLoading={q.isLoading || q.isFetching}
              listCount={emailDeliveryEvents.length}
              nextToken={q.data?.meta?.nextToken}
              onNextTokenChange={() =>
                setParams((prev) => {
                  return {
                    ...prev,
                    "ap.nextToken": q.data?.meta?.nextToken,
                    timestamp: Date.now(),
                  }
                })
              }
            />
          </Box>
        </Stack>
      </Stack>
      {selectedDelivery?.id && (
        <SaNotifDeliveriesEmailDrawer
          delivery={selectedDelivery}
          onClose={() => setSelectedDeliveryId("")}
        />
      )}
      {selectedDeliveryEventId && (
        <Drawer
          anchor="right"
          open={!!selectedDeliveryEventId}
          onClose={() => setSelectedDeliveryEventId("")}
          sx={{
            "& .MuiDrawer-paper": {
              width: "80vw",
              maxWidth: "1500px",
              maxHeight: "calc(100vh - 64px)",
              height: "calc(100vh - 64px)",
              paddingTop: "64px",
            },
          }}
        >
          <Stack direction="column" spacing={1} p={2}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    color: "primary.main",
                  }}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {selectedDeliveryEvent?.id}
                  </Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {selectedDeliveryEvent?.createdAt}
                  </Typography>
                </Stack>
              </Stack>
              <IconButton onClick={() => setSelectedDeliveryEventId("")}>
                <Close />
              </IconButton>
            </Stack>
            <Divider />

            <ObjectDisplayTable data={selectedDeliveryEvent} />
          </Stack>
        </Drawer>
      )}
    </Box>
  )
}

export default SaNotifDeliveriesEmailEvents
