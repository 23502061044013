import { Edit, Email } from "@mui/icons-material"
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { map, noop, omit, pick } from "lodash"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EmailExpeditor } from "shared/emailExpeditor"
import { EmailExpeditorThingTypeMrn } from "shared/emailExpeditorData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { useAppSelector } from "src/app/hooks"
import {
  useCreateThingsMutation,
  useDeleteThingsMutation,
  useUpdateThingsMutation,
} from "src/features/things/thingsApi"
import EmailExpeditorTenantCard from "./EmailExpeditorTenantCard"
import FormFields, { FormField } from "./FormFields"
import { SaTenantExpeditorSelectorDialog } from "./SaTenantExpeditorSelectorDialog"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"
import { selectSaList } from "src/features/things/thingsSlice"

const joiSchemaInfo = Joi.object({
  name: Joi.string().optional(),
  description: Joi.string().optional(),
  fromEmail: Joi.string().optional(),
})

type EmailExpeditorCardProps = {
  emailExpeditor: EmailExpeditor | undefined
  mode?: "edit" | "create"
  onComplete?: () => void
}

export const EmailExpeditorCard: FC<EmailExpeditorCardProps> = ({
  emailExpeditor,
  mode = "edit",
  onComplete = noop,
}) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const [addingTenant, setAddingTenant] = useState(false)
  const [updateThings, { isLoading: isUpdating }] = useUpdateThingsMutation()
  const [createThings, { isLoading: isCreating }] = useCreateThingsMutation()
  const [deleteThings] = useDeleteThingsMutation()

  const [snackMessage, setSnackMessage] = useState({
    message: "",
    severity: "success" as "success" | "error" | "warning" | "info" | undefined,
  })

  const formFields = useMemo(() => {
    return [
      {
        name: "name",
        fieldType: "text",
        label: t("name"),
        required: true,
        fullWidth: true,
        size: "small",
        gridProps: { xs: 12 },
      },
      // description
      {
        name: "description",
        fieldType: "text",
        label: t("description"),
        required: true,
        fullWidth: true,
        size: "small",
        gridProps: { xs: 12 },
      },
      // fromEmail
      {
        name: "fromEmail",
        fieldType: "text",
        label: t("fromEmail"),
        required: true,
        fullWidth: true,
        size: "small",
        gridProps: { xs: 12 },
      },
    ] as FormField[]
  }, [t])

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        const toMutate = {
          ...omit(data, ["tenantId"]),
          id: emailExpeditor?.id,
          thingTypeMrn: EmailExpeditorThingTypeMrn,
        }
        const mutate = mode === "edit" ? updateThings : createThings
        await mutate({
          body: { things: [toMutate] },
          // params: { tenantId },
        }).unwrap()
        setSnackMessage({
          message: t(
            mode === "edit"
              ? "itemUpdatedSuccessfully"
              : "itemCreatedSuccessfully",
          ),
          severity: "success",
        })
      } catch (error) {
        console.log(error)
        setSnackMessage({
          message: t(mode === "edit" ? "itemUpdatedError" : "itemCreatedError"),
          severity: "error",
        })
      } finally {
        setIsEditing(false)
        onComplete()
      }
    },
    [createThings, mode, onComplete, emailExpeditor?.id, t, updateThings],
  )

  const onDelete = useCallback(async () => {
    try {
      const toDelete = {
        id: emailExpeditor?.id,
        thingTypeMrn: EmailExpeditorThingTypeMrn,
      }

      await deleteThings({
        body: { things: [toDelete] },
        // params: { tenantId },
      }).unwrap()
      setSnackMessage({
        message: t("itemDeletedSuccessfully"),
        severity: "success",
      })
    } catch (error) {
      console.log(error)
      setSnackMessage({
        message: t("itemDeletedError"),
        severity: "error",
      })
    } finally {
      setIsEditing(false)
      onComplete()
    }
  }, [deleteThings, onComplete, emailExpeditor?.id, t])

  const tenants = useAppSelector((state) => selectSaList<Tenant>(state)).filter(
    (t) =>
      t.thingTypeMrn === TenantThingTypeMrn &&
      t.emailExpeditorId === emailExpeditor?.id,
  )

  const [showRemoveTenantConfirmDialog, setShowRemoveTenantConfirmDialog] =
    useState(false)
  const [tenantToRemove, setTenantToRemove] = useState<Tenant | undefined>(
    undefined,
  )
  const [
    showRemoveExpeditorConfirmDialog,
    setShowRemoveExpeditorConfirmDialog,
  ] = useState(false)
  const onRemoveAssociatedTenant = useCallback(async () => {
    try {
      if (!tenantToRemove) {
        console.error("No tenant to remove")
        return
      }
      // remove tenant from expeditor
      const toUpdate = {
        id: tenantToRemove.id,
        thingTypeMrn: TenantThingTypeMrn,
        emailExpeditorId: "",
      }
      await updateThings({
        body: { things: [toUpdate] },
      }).unwrap()
      setSnackMessage({
        message: t("itemUpdatedSuccessfully"),
        severity: "success",
      })
    } catch (error) {
      console.log(error)
      setSnackMessage({
        message: t("itemUpdatedError"),
        severity: "error",
      })
    } finally {
      setShowRemoveTenantConfirmDialog(false)
      setTenantToRemove(undefined)
    }
  }, [t, tenantToRemove, updateThings])
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 1,
        pb: 4,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6">
          {t(mode === "create" ? "newEmailExpeditor" : "emailExpeditor")}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {/* edit icon button */}
        {!isEditing && mode !== "create" && (
          <IconButton onClick={() => setIsEditing(true)}>
            <Edit />
          </IconButton>
        )}
      </Stack>
      <Stack direction="column" spacing={6}>
        {!isEditing && emailExpeditor && mode !== "create" && (
          <Stack direction="column" spacing={1} sx={{ pt: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6" fontWeight={"bold"}>
                {emailExpeditor.name}
              </Typography>
            </Stack>

            <Typography variant="body1">
              {emailExpeditor.description}
            </Typography>
          </Stack>
        )}
        {(isEditing || mode === "create") && (
          <Box sx={{ py: 4 }}>
            <Sl2FormContainer
              joiSchema={joiSchemaInfo}
              defaultValues={pick(emailExpeditor, [
                "name",
                "description",
                "fromEmail",
              ])}
              onError={(errors: any) => console.log(errors)}
              onSuccess={onSubmit}
            >
              <FormFields fields={formFields} />
              <Stack
                direction="row"
                spacing={4}
                justifyContent="flex-end"
                pt={2}
              >
                <Sl2Btn
                  onClick={() => setIsEditing(false)}
                  disabled={isUpdating}
                  variant="outlined"
                  sx={{ width: 150 }}
                >
                  {t("cancel")}
                </Sl2Btn>
                <Sl2Btn
                  type="submit"
                  disabled={isUpdating}
                  variant="contained"
                  sx={{ width: 150 }}
                >
                  {t(mode === "create" ? "create" : "modify")}
                </Sl2Btn>
              </Stack>
            </Sl2FormContainer>
          </Box>
        )}
        {mode !== "create" && (
          <>
            <Stack direction="column" spacing={1} sx={{ pt: 2 }}>
              <Typography variant="body1" fontWeight="bold">
                {t("fromEmail")}
              </Typography>
              <Typography variant="caption">
                {t("fromEmailDescription")}
              </Typography>
              <Stack
                direction="row"
                alignContent={"center"}
                spacing={6}
                alignItems={"center"}
                // sx={{ py: 4 }}
              >
                <Stack
                  direction="row"
                  alignContent={"center"}
                  spacing={1}
                  alignItems={"center"}
                >
                  <Email style={{ fontSize: "1.8em", color: "slategray" }} />
                  <Typography variant="body1">
                    {emailExpeditor?.fromEmail}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction="column" spacing={1} sx={{ pt: 2 }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction="column" spacing={1}>
                  {/* row with count */}
                  <Stack direction="row" spacing={2}>
                    <Typography variant="body1" fontWeight="bold">
                      {t("associatedOrgs")}
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {`(${tenants?.length})`}
                    </Typography>
                  </Stack>
                  <Typography variant="caption">
                    {t("emailExpeditorAssociatedOrgsDescription")}
                  </Typography>
                </Stack>
                <Box width={100}>
                  <Sl2Btn
                    variant="outlined"
                    fullWidth
                    onClick={() => setAddingTenant(true)}
                  >
                    {t("add")}
                  </Sl2Btn>
                </Box>
              </Stack>
              <Stack direction="column">
                {tenants?.length > 0 ? (
                  map(tenants, (tenant) => (
                    <EmailExpeditorTenantCard
                      tenant={tenant}
                      key={tenant.id}
                      onRemove={() => {
                        setTenantToRemove(tenant)
                        setShowRemoveTenantConfirmDialog(true)
                      }}
                    />
                  ))
                ) : (
                  <Typography variant="body2">
                    {t("noAssociatedOrgs")}
                  </Typography>
                )}
              </Stack>
              <Stack direction="column" spacing={1}>
                <Box sx={{ pt: 6 }} />
                <Typography variant="body1" fontWeight="bold">
                  {t("deleteExpeditor")}
                </Typography>
                <Typography variant="body2">
                  {t("deleteExpeditorDescription")}
                </Typography>
                <Box>
                  <Sl2Btn
                    variant="outlined"
                    color="error"
                    disabled={tenants.length > 0}
                    onClick={() => setShowRemoveExpeditorConfirmDialog(true)}
                  >
                    {t("delete")}
                  </Sl2Btn>
                </Box>
              </Stack>
            </Stack>
          </>
        )}
        {/* typoe grpah codefor id */}
        <Typography variant="caption" color="textSecondary">
          {emailExpeditor?.id}
        </Typography>
      </Stack>
      {addingTenant && emailExpeditor?.id && (
        <SaTenantExpeditorSelectorDialog
          expeditorId={emailExpeditor.id}
          expeditorKey="emailExpeditorId"
          onClose={() => setAddingTenant(false)}
          onComplete={() => setAddingTenant(false)}
        />
      )}
      <Snackbar
        open={!!snackMessage?.message}
        autoHideDuration={6000}
        onClose={() => setSnackMessage({ message: "", severity: undefined })}
      >
        <Alert severity={snackMessage.severity}>{snackMessage.message}</Alert>
      </Snackbar>
      {showRemoveTenantConfirmDialog && (
        <Dialog open={true}>
          <DialogTitle>{t("disassociateExpeditorTenant")}</DialogTitle>
          <DialogContent sx={{ minHeight: 100 }}>
            <DialogContentText>
              {t("disassociateExpeditorTenantConfirmMessage")}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Sl2Btn
              onClick={() => {
                setShowRemoveTenantConfirmDialog(false)
                setTenantToRemove(undefined)
              }}
              variant="outlined"
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              onClick={() => {
                onRemoveAssociatedTenant()
              }}
              variant="contained"
              color="error"
            >
              {t("dissociate")}
            </Sl2Btn>
          </DialogActions>
        </Dialog>
      )}
      {showRemoveExpeditorConfirmDialog && (
        <Dialog open={true}>
          <DialogTitle>{t("deleteExpeditor")}</DialogTitle>
          <DialogContent sx={{ minHeight: 100 }}>
            <DialogContentText>
              {t("deleteExpeditorConfirmMessage")}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Sl2Btn
              onClick={() => {
                setShowRemoveExpeditorConfirmDialog(false)
              }}
              variant="outlined"
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              onClick={() => {
                onDelete()
              }}
              variant="contained"
              color="error"
            >
              {t("delete")}
            </Sl2Btn>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  )
}

export default EmailExpeditorCard
