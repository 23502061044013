import { MoreVert } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import EventNoteIcon from "@mui/icons-material/EventNote"
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff"
import PaymentIcon from "@mui/icons-material/Payment"
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard"
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useHover } from "@uidotdev/usehooks"
import moment from "moment"
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import {
  ActionReqExecutionModeEnum,
  ActionReqThingTypeMrn,
  ActionReqTypeEnum,
} from "shared/actionReqData"
import { Client } from "shared/client"
import { ConfigurationReservationActionRefNameEnum } from "shared/configurationData"
import { Locker } from "shared/locker"
import { VirtualLockerActionEnum } from "shared/lockerData"
import { LockerDoorReservationSession } from "shared/lockerDoorReservationSession"
import { Reservation } from "shared/reservation"
import {
  ReservationAudEventEnum,
  ReservationAudThingTypeMrn,
} from "shared/reservationAudData"
import {
  ReservationActionEnum,
  ReservationThingTypeMrn,
} from "shared/reservationData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { SstDoor } from "shared/sst"
import { Thing } from "shared/thing"
import { ModeContext } from "src/ModeContext"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { RootState } from "src/app/store"
import { appConfig } from "src/appConfig"
import { useGetMblsConnectorClientsQuery } from "src/features/mblsConnector/mblsConenctorApi"
import { MblsConnectorClient } from "src/features/mblsConnector/mblsConnector"
import { selectCurrentTenant, selectTenantId } from "src/features/me/meSlice"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
} from "src/features/things/thingsApi"
import {
  selectClientSelectionDefaultValues,
  selectClientSelectionList,
  selectClientSelectionSearchValues,
  selectMblsReservation,
  selectMblsReservationClientId,
  selectThingById,
  thingsActions,
} from "src/features/things/thingsSlice"
import useClearMblsReservation from "src/hooks/useClearMblsReservation"
import { useKeyPress } from "src/hooks/useKeyPress"
import { useLockerActions } from "src/hooks/useLockerActions"
import useLockerDisplayValues from "src/hooks/useLockerDisplayValues"
import { useReservation } from "src/hooks/useReservation"
import useTenantConfig from "src/hooks/useTenantConfig"
import { v4 as uuidv4 } from "uuid"
import { OsLockerCancelReservationDialog } from "./OsLockerCancelReservationDialog"
import { OsLockerMarkAsDepositedDialog } from "./OsLockerMarkAsDepositedDialog"
import { OsLockerOpenDoorDialog } from "./OsLockerOpenDoorDialog"
import { OsLockerReservationForm } from "./OsLockerReservationForm"
import OsLockerReservationInfo from "./OsLockerReservationInfo"
import OsLockerReservationStepper from "./OsLockerReservationStepper"
import { OsLockerShowAdminCodeDialog } from "./OsLockerShowAdminCodeDialog"
import { OsLockerShowPickupCodeDialog } from "./OsLockerShowPickupCodeDialog"
import { OsReservationAmountEdit } from "./OsReservationAmountEdit"
import { OsReservationAuditTrail } from "./OsReservationAuditTrail"
import { OsReservationClientSelector } from "./OsReservationClientSelector"
import RectangularChip from "./RectangularChip"
import { Sl2Btn } from "./Sl2Btn"
import TraceTooltip from "./TraceTooltip"

export type OsLockerDoorDrawerProps = {
  onClose: (sessionId?: string) => void
  door: SstDoor
  reservation?: Reservation
  locker: Locker
  session?: LockerDoorReservationSession
  modName: string
  isCreateMode?: boolean
}

export const OsLockerDoorDrawer: FC<OsLockerDoorDrawerProps> = ({
  onClose: inOnClose,
  door,
  reservation: r,
  locker,
  session,
  modName,
  isCreateMode = false,
}) => {
  const { t } = useTranslation()

  const mode = useContext(ModeContext)

  const tenantId = useAppSelector(selectTenantId)

  const reservation = useAppSelector((s: RootState) =>
    selectThingById(s, r?.id || "-1"),
  ) as Reservation

  const {
    openDoor,
    getPackage,
    cancelReservation,
    simulateExpiration,
    simulatePayment,
  } = useLockerActions({
    lockerId: locker?.id || "",
    sstLockerId: locker?.sstLockerId || "",
    sstDoorId: door.door_id,
    sstReservationId: reservation?.sstReservation?._id,
    tenantId,
    reservationId: reservation?.id,
  })

  const dispatch = useAppDispatch()

  const onClose = useCallback(() => {
    inOnClose(session?.id)
    dispatch(thingsActions.setHighlightedReservationId(""))
    dispatch(thingsActions.clearClientSelectionDefaultValues())
    dispatch(thingsActions.clearClientSelectionSearchValues())
  }, [dispatch, inOnClose, session?.id])

  useKeyPress("Escape", inOnClose)

  const onOpenDoor = useCallback(
    async (employeeCode?: string, shouldClose?: boolean) => {
      await openDoor(employeeCode)
      if (shouldClose === false) return
      onClose()
    },
    [onClose, openDoor],
  )

  const [createThings] = useCreateThingsMutation()

  const tenant = useAppSelector(selectCurrentTenant)

  const [isProcessing, setIsProcessing] = useState(false)

  const onGetPackage = useCallback(
    async (employeeCode?: string) => {
      setIsProcessing(true)

      await getPackage(employeeCode)
      onClose()
      setIsProcessing(false)
    },
    [getPackage, onClose],
  )

  const [searchParams] = useSearchParams()

  const searchValues = useAppSelector(selectClientSelectionSearchValues)

  // at least one of the search values must have at least 3 characters

  const hasSearchValuesMinChars = useMemo(
    () =>
      Object.values(searchValues).some(
        (v) => v && v?.length >= appConfig.minSearchChars,
      ),
    [searchValues],
  )
  const {
    data,
    isLoading: isMblsConnectorClientsLoading,
    isFetching: isMblsConnectorClientsFetching,
    refetch: refetchMblsConnectorClients,
  } = useGetMblsConnectorClientsQuery(
    {
      params: {
        ...searchValues,
        tenantId: tenant?.id,
        id: searchParams.get("clientId") ?? undefined,
      },
    },
    {
      skip: !tenant?.mblsProviderId || !hasSearchValuesMinChars,
    },
  )

  const isMblsConnectorProcessing =
    isMblsConnectorClientsLoading || isMblsConnectorClientsFetching

  const [mblsClients, setMblsClients] = useState<MblsConnectorClient[]>([])

  useEffect(() => {
    if (data) {
      setMblsClients(data.data)
    }
  }, [data])

  useEffect(() => {
    setMblsClients([])
  }, [door.door_id])

  const filteredClients = useAppSelector(selectClientSelectionList)

  const qSl2Clients = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetClients,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !tenantId,
    },
  )

  const onSelectClient = useCallback(
    (client: Client | MblsConnectorClient) => {
      dispatch(
        thingsActions.updateClientSelectionDefaultValues({
          clientId: client.id,
        }),
      )
    },
    [dispatch],
  )
  const [isCancelling, setIsCancelling] = useState(false)

  const onCancel = useCallback(
    async (employeeCode?: string) => {
      setIsCancelling(true)
      try {
        await cancelReservation(employeeCode)
      } catch (err: any) {
        console.error("Error cancelling reservation", err)
      } finally {
        setIsCancelling(false)
      }
      onClose()
    },
    [cancelReservation, onClose],
  )

  const {
    hasExpired,
    statusColor,
    statusTextColor,
    isCompleted,
    hasDeposited,
    hasPickedUp,
  } = useReservation({
    reservation,
    session,
    door,
    hasDoorLink: !!door.links?.find((l) => l.rel === "reservation"),
  })

  const menuOptions = useMemo(() => {
    const items = [ReservationActionEnum.openDoor]

    if (!isCompleted && reservation?.id) {
      items.push("cancelReservation")
    }

    if (
      !isCompleted &&
      !hasExpired &&
      reservation?.id &&
      !reservation?.sstReservation?.reception_time &&
      !reservation?.sstReservation?.delivery_time
    ) {
      items.push(ReservationActionEnum.markAsDeposited)
    }
    if (!isCompleted && !hasExpired) {
      items.push(ReservationActionEnum.showAdminCode)
    }
    if (!isCompleted && !hasExpired) {
      items.push(ReservationActionEnum.showPickupCode)
    }
    if (locker?.isVirtual && reservation?.id && !hasExpired) {
      items.push(ReservationActionEnum.simulateExpiration)
    }
    if (
      locker?.isVirtual &&
      reservation?.id &&
      !hasExpired &&
      locker?.sstLocker?.payment
    ) {
      items.push(ReservationActionEnum.simulatePayment)
    }
    return items
  }, [
    hasExpired,
    isCompleted,
    locker?.isVirtual,
    locker?.sstLocker?.payment,
    reservation?.id,
    reservation?.sstReservation?.delivery_time,
    reservation?.sstReservation?.reception_time,
  ])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionClick = (option: string) => {
    if (option === ReservationActionEnum.openDoor) {
      setIsOpenDoorDialogOpen(true)
    } else if (option === ReservationActionEnum.cancelReservation) {
      setIsCancelDialogOpen(true)
    } else if (option === ReservationActionEnum.markAsDeposited) {
      setIsMarkAsDepositedDialogOpen(true)
    } else if (option === ReservationActionEnum.showAdminCode) {
      setIsShowingAdminCodeDialog(true)
    } else if (option === ReservationActionEnum.showPickupCode) {
      setIsShowingPickupCodeDialog(true)
    } else if (option === ReservationActionEnum.simulateExpiration) {
      simulateExpiration(reservation?.id || "")
    } else if (option === ReservationActionEnum.simulatePayment) {
      setIsSimulatePaymentDialogOpen(true)
    }
    setAnchorEl(null)
  }

  const [isOpenDoorDialogOpen, setIsOpenDoorDialogOpen] = useState(false)

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)

  const [isMarkAsDepositedDialogOpen, setIsMarkAsDepositedDialogOpen] =
    useState(false)

  const [isSimulatePaymentDialogOpen, setIsSimulatePaymentDialogOpen] =
    useState(false)

  const [simulatePaymentAmount, setSimulatePaymentAmount] = useState(
    reservation?.amountToCollect || 0,
  )

  const [simulatePaymentAmountError, setSimulatePaymentAmountError] =
    useState("")

  const [isSumulatePaymentProcessing, setIsSimulatePaymentProcessing] =
    useState(false)
  const [showAuditTrail, setShowAuditTrail] = useState(false)

  useEffect(() => {
    setShowAuditTrail(false)
  }, [reservation?.id])

  const [isShowingAdminCodeDialog, setIsShowingAdminCodeDialog] =
    useState(false)

  const [isShowingPickupCodeDialog, setIsShowingPickupCodeDialog] =
    useState(false)

  const onShow = useCallback(
    async (employeeCode?: string) => {
      try {
        // create reservation audit entry
        const aud = {
          thingTypeMrn: ReservationAudThingTypeMrn,
          reservationId: reservation?.id,
          tenantId: reservation?.tenantId,
          event: ReservationAudEventEnum.adminCodeShown,
          employeeCode,
          details: {},
        }

        await createThings({
          body: { things: [aud] },
          params: { tenantId },
        }).unwrap()
      } catch (err: any) {
        console.error("Error cancelling reservation", err)
      } finally {
      }
    },
    [createThings, reservation?.id, reservation?.tenantId, tenantId],
  )

  const clientSelectionDefaultValues = useAppSelector(
    selectClientSelectionDefaultValues,
  )

  const mblsReservationClientId = useAppSelector(selectMblsReservationClientId)

  const displayClientSelector = useMemo(() => {
    return (
      isCreateMode &&
      !mblsReservationClientId &&
      !clientSelectionDefaultValues.clientId &&
      hasSearchValuesMinChars
    )
  }, [
    clientSelectionDefaultValues.clientId,
    hasSearchValuesMinChars,
    isCreateMode,
    mblsReservationClientId,
  ])

  const mblsReservation = useAppSelector(selectMblsReservation)

  const { clearMblsReservation } = useClearMblsReservation()

  const onFormSuccess = useCallback(
    (result: { ids: string[]; things: Thing[] }) => {
      const newReservation = result?.things?.find(
        (t: Thing) => t.thingTypeMrn === ReservationThingTypeMrn,
      ) as Reservation

      if (newReservation) {
        dispatch(thingsActions.setSelectedReservationId(newReservation.id))
      }
      if (mblsReservation?.clientId) {
        clearMblsReservation(false)
      }
    },
    [clearMblsReservation, dispatch, mblsReservation?.clientId],
  )

  // isSimulatingCode
  const [isSimulatingCode, setIsSimulatingCode] = useState(false)
  const onSimulateCode = useCallback(
    async (code: string) => {
      try {
        setIsSimulatingCode(true)
        const actionReq = {
          thingTypeMrn: ActionReqThingTypeMrn,
          tenantId,
          idempotencyKey: uuidv4(),
          actionType: ActionReqTypeEnum.TriggerVirtualLockerAction,
          executionMode: ActionReqExecutionModeEnum.sync,
          inputData: {
            lockerId: locker.id,
            lockerAction: VirtualLockerActionEnum.SubmitCode,
            code,
          },
        }
        await createThings({
          body: { things: [actionReq] },
          params: { tenantId },
        }).unwrap()
      } catch (err: any) {
        console.error("Error submitting code", err)
      } finally {
        setIsSimulatingCode(false)
      }
    },
    [createThings, locker.id, tenantId],
  )
  const l = useLockerDisplayValues(locker)

  const configs = useTenantConfig(tenantId)

  const [ref, hovering] = useHover()

  // sanity check
  if (!door) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    )
  }
  const mt = ["embedded", "RxPro"].includes(mode)
    ? "0px"
    : `${appConfig.mainAppBarHeight}px`

  return (
    <Drawer
      open={true}
      onClose={onClose}
      anchor="right"
      variant="persistent"
      sx={{
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Box sx={{ mt }} />
      <Stack
        direction="row"
        spacing={0}
        alignItems="flex-start"
        // sx={{
        //   height: `calc(100vh - ${appConfig.mainAppBarHeight}px)`,
        //   // position: "absolute",
        //   // right: 0,
        //   // top: 0,
        // }}
      >
        {displayClientSelector && (
          <Paper
            elevation={0}
            variant="outlined"
            sx={{
              height: "100vh",
              overflowY: "auto",
              bgcolor: "#FAFBFB",
            }}
          >
            <OsReservationClientSelector
              mblsClients={mblsClients as unknown as MblsConnectorClient[]}
              clients={filteredClients}
              isLoading={isMblsConnectorProcessing}
              onSelectMbslClient={onSelectClient}
              onSelectClient={onSelectClient}
              onRefetchMblsClient={refetchMblsConnectorClients}
              onRefetchClient={qSl2Clients.refetch}
            />
          </Paper>
        )}
        <Paper
          variant="elevation"
          elevation={0}
          sx={{
            // pt: "74px",
            // height: "100%",
            width: appConfig.itemPreviewDrawerWidth,
            // bgcolor: "red",
            px: 2,
          }}
        >
          <Stack direction="column" spacing={2} sx={{ p: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="caption" fontWeight="bold">
                  {modName}
                </Typography>
                <Typography variant="caption">{l.i18n.name}</Typography>
              </Stack>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <SpaceDashboardIcon />
                <Typography variant="h6">{`${t("door")}`}</Typography>
                {/* round box with lightblue backgroud, centered vertically */}
                <Box
                  sx={{
                    bgcolor: statusColor,
                    color: statusTextColor,
                    borderRadius: "50%",
                    borderColor: statusTextColor,
                    border: "1px solid",
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  {door.door_label}
                </Box>
                <Box sx={{ pl: 2 }} />
                <Chip
                  label={door.size}
                  sx={{
                    // very light grey background, black text, rounded corners
                    bgcolor: "#f0f0f0",
                    color: "black",
                    borderRadius: "5px",
                  }}
                />
              </Stack>
              <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVert
                    sx={{
                      color: "grey",
                    }}
                  />
                </IconButton>
              </Box>
            </Stack>
          </Stack>

          <Divider sx={{ my: 3 }} />
          {reservation?.id ? (
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent={"space-between"}
                sx={{
                  mb: 3,
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <HistoryToggleOffIcon />
                  <Typography
                    variant="body1"
                    // fontWeight="bold"
                    sx={{
                      mb: 2,
                    }}
                  >
                    {t("currentReservation")}
                  </Typography>
                  {locker.sstLocker?.payment && !hasPickedUp && (
                    <Stack direction="row" spacing={0} alignItems="center">
                      <Chip
                        ref={ref}
                        variant="outlined"
                        size="small"
                        label={
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            sx={{ color: "primary.main" }}
                          >
                            <PaymentIcon sx={{ fontSize: "1.2em" }} />
                            <Typography variant="caption" fontWeight="bold">
                              {`${formatCadCurrency(
                                reservation?.amountToCollect,
                              )}`}
                            </Typography>

                            <OsReservationAmountEdit
                              reservation={reservation}
                              visible={hovering}
                            />
                          </Stack>
                        }
                      />
                    </Stack>
                  )}
                </Stack>

                {reservation && reservation?.sstReservation && tenantId && (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      color: "primary.main",
                      bgcolor: "grey.100",
                      p: 1,
                      borderRadius: "10px",
                    }}
                  >
                    <EventNoteIcon
                      sx={{
                        fontSize: "1.2em",
                      }}
                    />
                    <Typography variant="body2" fontWeight="bold">
                      {`# ${reservation.sstReservation.number}`}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <OsLockerReservationInfo reservation={reservation} />
              {reservation?.notes && (
                <Card
                  sx={{ p: 1, maxHeight: "200px", overflowY: "auto" }}
                  variant="outlined"
                >
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption" fontWeight="bold">
                      {t("notes")}
                    </Typography>
                    <Typography variant="body2">
                      {reservation?.notes}
                    </Typography>
                  </Stack>
                </Card>
              )}
              {reservation?.language && (
                <Card
                  sx={{ p: 1, maxHeight: "200px", overflowY: "auto" }}
                  variant="outlined"
                >
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption" fontWeight="bold">
                      {t("communicationLanguage")}
                    </Typography>
                    <Typography variant="body2">
                      {t(reservation?.language)}
                    </Typography>
                  </Stack>
                </Card>
              )}
              {!hasPickedUp && !hasDeposited && !hasExpired && (
                <Stack
                  direction="column"
                  spacing={1}
                  alignItems="center"
                  className={`delivery-code-container`}
                  sx={{
                    bgcolor: "#EBF7F4",
                    color: "primary.main",
                    p: 1,
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    className={`uf-delivery-code-container`}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      {`${t("depositCode")}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      fontFamily={"monospace"}
                    >
                      {reservation.sstReservation?.delivery_code}
                    </Typography>
                  </Stack>
                  <Typography variant="caption">
                    {`${t("depositCodeInfo")}`}
                  </Typography>
                  {locker.isVirtual && (
                    <>
                      {isSimulatingCode ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <CircularProgress size={15} />
                          <Typography variant="caption">
                            {t("processingCode")}
                          </Typography>
                        </Stack>
                      ) : (
                        <Box
                          sx={{
                            textDecoration: "underline",
                          }}
                        >
                          <Typography
                            variant="caption"
                            onClick={() =>
                              onSimulateCode(
                                reservation.sstReservation?.delivery_code || "",
                              )
                            }
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {t("simulateCode")}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Stack>
              )}
              {!hasExpired &&
                hasDeposited &&
                !hasPickedUp &&
                locker.isVirtual && (
                  <Stack
                    direction="column"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      bgcolor: "#EBF7F4",
                      color: "primary.main",
                      p: 1,
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="body2" fontWeight="bold">
                        {`${t("receptionCode")}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        fontFamily={"monospace"}
                      >
                        {reservation.sstReservation?.reception_code}
                      </Typography>
                    </Stack>
                    <Typography variant="caption" color="primary.main">
                      {`${t("receptionCodeInfo")}`}
                    </Typography>
                    {locker.isVirtual && (
                      <>
                        {isSimulatingCode ? (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <CircularProgress size={15} />
                            <Typography variant="caption">
                              {t("processingCode")}
                            </Typography>
                          </Stack>
                        ) : (
                          <Box
                            sx={{
                              textDecoration: "underline",
                            }}
                          >
                            <Typography
                              variant="caption"
                              onClick={() =>
                                onSimulateCode(
                                  reservation.sstReservation?.reception_code ||
                                    "",
                                )
                              }
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              {t("simulateCode")}
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </Stack>
                )}
              {hasExpired && (
                <Stack
                  direction="column"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    bgcolor: statusColor,
                    color: statusTextColor,
                    p: 1,
                    textAlign: "center",
                    borderRadius: "8px",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="body2" fontWeight="bold">
                      {`${t("expirationCode")}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      fontFamily={"monospace"}
                    >
                      {reservation.sstReservation?.admin_code}
                    </Typography>
                  </Stack>
                  <Typography variant="caption">
                    {`${t("expirationCodeInfo")}`}
                  </Typography>
                  {locker.isVirtual && (
                    <>
                      {isSimulatingCode ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <CircularProgress size={15} />
                          <Typography variant="caption">
                            {t("processingCode")}
                          </Typography>
                        </Stack>
                      ) : (
                        <Box
                          sx={{
                            textDecoration: "underline",
                          }}
                        >
                          <Typography
                            variant="caption"
                            onClick={() =>
                              onSimulateCode(
                                reservation.sstReservation?.admin_code || "",
                              ).then(onClose)
                            }
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {t("simulateCode")}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Stack>
              )}
              <Divider />
              {/* <Sl2Btn
                variant="outlined"
                fullWidth
                startIcon={<CancelIcon sx={{ color: "error.main" }} />}
                sx={{ height: appConfig.btnHeight }}
                onClick={onCancel}
              >
                {t("cancelReservation")}
              </Sl2Btn>
              <Divider /> */}
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Link
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => setShowAuditTrail(!showAuditTrail)}
                >
                  <Typography variant="caption">
                    {showAuditTrail ? t("hideAuditTrail") : t("showAuditTrail")}
                  </Typography>
                </Link>
              </Stack>
              {!showAuditTrail && (
                <OsLockerReservationStepper
                  door={door}
                  reservation={JSON.parse(JSON.stringify(reservation))}
                  session={session}
                />
              )}
              {showAuditTrail && (
                <OsReservationAuditTrail reservationId={reservation.id} />
              )}
              <Box pt={6} />
              {reservation?.sstReservation?.status && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="caption" sx={{ mt: 2 }}>
                    {t("status")}
                  </Typography>
                  <RectangularChip
                    label={t(
                      `sstReservationStatus.${reservation?.sstReservation?.status}`,
                    )}
                    variant="outlined"
                    size="small"
                  />
                </Stack>
              )}
              <Divider />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <TraceTooltip
                  text={reservation.traceId || "n/a"}
                  tooltipTitle={
                    <Typography variant="caption" sx={{ mt: 2 }}>
                      {reservation.id}
                    </Typography>
                  }
                />
                {reservation.updatedAt && (
                  <Typography variant="caption" sx={{ mt: 2 }}>
                    {`${t("updated")} ${moment(reservation.updatedAt).format(
                      "YYYY-MM-DD HH:mm",
                    )}`}
                  </Typography>
                )}
              </Stack>
            </Stack>
          ) : isCreateMode ? (
            <OsLockerReservationForm
              locker={locker}
              doorId={door.door_id}
              onClose={onClose}
              // @ts-ignore
              displayNewClientCheckbox={!["embedded", "RxPro"].includes(mode)}
              onSuccess={onFormSuccess}
            />
          ) : null}
        </Paper>
      </Stack>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleOptionClick(option)}
            sx={{
              color: "primary.main",
              minWidth: "200px",
            }}
          >
            {t(option)}
          </MenuItem>
        ))}
      </Menu>
      {isOpenDoorDialogOpen && (
        <OsLockerOpenDoorDialog
          onClose={() => setIsOpenDoorDialogOpen(false)}
          onOpenDoor={onOpenDoor}
          onCancel={onCancel}
          employeeCodeRequired={
            configs[
              ConfigurationReservationActionRefNameEnum
                .actionRequireEmployeeCode_openDoor
            ]?.bVal ?? true
          }
        />
      )}
      {isCancelDialogOpen && (
        <OsLockerCancelReservationDialog
          onCancel={onCancel}
          onOpenDoor={onOpenDoor}
          onClose={() => setIsCancelDialogOpen(false)}
          hasDeposited={hasDeposited}
          hasExpired={hasExpired}
          employeeCodeRequired={
            configs[
              ConfigurationReservationActionRefNameEnum
                .actionRequireEmployeeCode_cancelReservation
            ]?.bVal ?? true
          }
          adminCode={reservation?.sstReservation?.admin_code || "n/a"}
        />
      )}
      {isMarkAsDepositedDialogOpen && (
        <OsLockerMarkAsDepositedDialog
          onClose={() => setIsMarkAsDepositedDialogOpen(false)}
          onMarkAsDeposited={onGetPackage}
          employeeCodeRequired={
            configs[
              ConfigurationReservationActionRefNameEnum
                .actionRequireEmployeeCode_markAsDeposited
            ]?.bVal ?? true
          }
        />
      )}
      {isShowingAdminCodeDialog && (
        <OsLockerShowAdminCodeDialog
          onClose={() => setIsShowingAdminCodeDialog(false)}
          adminCode={reservation?.sstReservation?.admin_code || "n/a"}
          onShow={onShow}
          employeeCodeRequired={
            configs[
              ConfigurationReservationActionRefNameEnum
                .actionRequireEmployeeCode_showAdminCode
            ]?.bVal ?? true
          }
        />
      )}
      {isShowingPickupCodeDialog && (
        <OsLockerShowPickupCodeDialog
          onClose={() => setIsShowingPickupCodeDialog(false)}
          pickupCode={reservation?.sstReservation?.reception_code || "n/a"}
        />
      )}
      {isSimulatePaymentDialogOpen && (
        <Dialog
          open={isSimulatePaymentDialogOpen}
          onClose={() => setIsOpenDoorDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("simulatePaymentConfirmation")}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("simulatePaymentConfirmationText")}
            </DialogContentText>
            <TextField
              sx={{
                mt: 3,
              }}
              label={t("amount")}
              variant="outlined"
              fullWidth
              value={simulatePaymentAmount}
              onChange={(e) => {
                setSimulatePaymentAmountError("")

                setSimulatePaymentAmount(e.target.value as any)
              }}
              onBlur={() => {
                // check is number
                if (isNaN(simulatePaymentAmount)) {
                  setSimulatePaymentAmountError(t("amountMustBeNumber"))
                }

                // validate
                if (simulatePaymentAmount < 0) {
                  setSimulatePaymentAmountError(t("amountMustBePositive"))
                } else if (
                  simulatePaymentAmount > reservation?.amountToCollect
                ) {
                  setSimulatePaymentAmountError(t("amountMustBeLessThanTotal"))
                }
              }}
              helperText={simulatePaymentAmountError}
              error={!!simulatePaymentAmountError}
            />
          </DialogContent>
          <DialogActions>
            <Sl2Btn
              onClick={() => setIsSimulatePaymentDialogOpen(false)}
              color="primary"
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              onClick={async () => {
                try {
                  setIsSimulatePaymentProcessing(true)
                  await simulatePayment(reservation?.id, simulatePaymentAmount)
                } catch (err: any) {
                  console.error("Error simulating payment", err)
                } finally {
                  setIsSimulatePaymentDialogOpen(false)
                  setIsSimulatePaymentProcessing(false)
                }
              }}
              color="primary"
              autoFocus
              variant="contained"
              disabled={isSumulatePaymentProcessing}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {isSumulatePaymentProcessing && <CircularProgress size={15} />}
                <span>{t("simulatePayment")}</span>
              </Stack>
            </Sl2Btn>
          </DialogActions>
        </Dialog>
      )}
    </Drawer>
  )
}

export default OsLockerDoorDrawer

const formatCadCurrency = (amount: number) => {
  return amount?.toLocaleString("en-CA", { style: "currency", currency: "CAD" })
}
