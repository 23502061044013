// os : organization space
export const SharedAccessPatternOsList = [
  "GetLockers",
  "GetClients",
  "GetConfigurationsByTenant",
  "GetCredentialsByUser",
  "GetGroupIamActions",
  "GetGroupsByTenant",
  "GetGroupTUsByGroupId",
  "GetGroupTUsByTenantAndUser",
  "GetLockerById",
  "GetLockerCurrentReservations",
  "GetReservationAuds",
  "GetReservationAudsById",
  "GetReservationById",
  "GetReservations",
  "GetSstReservations",
  "GetUsersByTenant",
  "GetReservationAttemptByExternalId",
] as const;

export const SharedAccessPatternOsEnum = {
  GetLockers: "GetLockers",
  GetClients: "GetClients",
  GetConfigurationsByTenant: "GetConfigurationsByTenant",
  GetCredentialsByUser: "GetCredentialsByUser",
  GetGroupIamActions: "GetGroupIamActions",
  GetGroupsByTenant: "GetGroupsByTenant",
  GetGroupTUsByGroupId: "GetGroupTUsByGroupId",
  GetGroupTUsByTenantAndUser: "GetGroupTUsByTenantAndUser",
  GetLockerById: "GetLockerById",
  GetLockerCurrentReservations: "GetLockerCurrentReservations",
  GetReservationAuds: "GetReservationAuds",
  GetReservationAudsById: "GetReservationAudsById",
  GetReservationById: "GetReservationById",
  GetReservations: "GetReservations",
  GetSstReservations: "GetSstReservations",
  GetUsersByTenant: "GetUsersByTenant",
  GetReservationAttemptByExternalId: "GetReservationAttemptByExternalId",
} as const;

export type SharedAccessPatternOsName =
  (typeof SharedAccessPatternOsList)[number];

// us : user space
export const SharedAccessPatternUsList = [
  "GetMe",
  "GetIamActions",
  "GetMods",
  "GetResources",
  "GetMyTenants",
] as const;

export const SharedAccessPatternUsEnum = {
  GetMe: "GetMe",
  GetIamActions: "GetIamActions",
  GetMods: "GetMods",
  GetResources: "GetResources",
  GetMyTenants: "GetMyTenants",
} as const;

export type SharedAccessPatternUsName =
  (typeof SharedAccessPatternUsList)[number];

// sa : super admin space
export const SharedAccessPatternSaList = [
  "GetItemByKeys",
  "SaGetEmailExpeditors",
  "SaGetLockers",
  "SaGetMeventLogByThingType",
  "SaGetMeventLogByTrace",
  "SaGetReservationAudsByEmployeeCode",
  "SaGetReservationAudsByEvent",
  "SaGetSeedTrackers",
  "SaGetSmsExpeditors",
  "SaGetTenants",
  "SaGetUsers",
  "SaGetSmsDeliveries",
  "SaGetEmailDeliveries",
  "SaGetEmailDeliverybyId",
  "SaGetEmailDeliveriesByEventType",
  "SaGetEmailDeliveryEventsByEmailDeliveryId",
  "SaGetDeletedThings",
  "SaGetAdminLogs",
] as const;

export const SharedAccessPatternSaEnum = {
  GetItemByKeys: "GetItemByKeys",
  SaGetEmailExpeditors: "SaGetEmailExpeditors",
  SaGetLockers: "SaGetLockers",
  SaGetMeventLogByThingType: "SaGetMeventLogByThingType",
  SaGetMeventLogByTrace: "SaGetMeventLogByTrace",
  SaGetReservationAudsByEmployeeCode: "SaGetReservationAudsByEmployeeCode",
  SaGetReservationAudsByEvent: "SaGetReservationAudsByEvent",
  SaGetSeedTrackers: "SaGetSeedTrackers",
  SaGetSmsExpeditors: "SaGetSmsExpeditors",
  SaGetTenants: "SaGetTenants",
  SaGetUsers: "SaGetUsers",
  SaGetSmsDeliveries: "SaGetSmsDeliveries",
  SaGetEmailDeliveries: "SaGetEmailDeliveries",
  SaGetEmailDeliverybyId: "SaGetEmailDeliverybyId",
  SaGetEmailDeliveriesByEventType: "SaGetEmailDeliveriesByEventType",
  SaGetEmailDeliveryEventsByEmailDeliveryId:
    "SaGetEmailDeliveryEventsByEmailDeliveryId",
  SaGetDeletedThings: "SaGetDeletedThings",
  SaGetAdminLogs: "SaGetAdminLogs",
} as const;

export type SharedAccessPatternSaName =
  (typeof SharedAccessPatternSaList)[number];
