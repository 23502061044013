import { Card, CircularProgress, Stack, Typography } from "@mui/material"
import { FC, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Client } from "shared/client"
import { MblsConnectorClient } from "src/features/mblsConnector/mblsConnector"

import { ModeContext } from "src/ModeContext"
import { OsCreateMblsClientDialog } from "./OsCreateMblsClientDialog"
import { OsCreateSl2ClientDialog } from "./OsCreateSl2ClientDialog"
import { OsReservationClientMblsSelectorItem } from "./OsReservationClientMblsSelectorItem"
import { OsReservationClientSelectorItem } from "./OsReservationClientSelectorItem"
import { Sl2Btn } from "./Sl2Btn"

// feature flag
const ffCreateClientEnabled =
  localStorage.getItem("ffCreateClientEnabled") === "true"

type OsReservationClientSelectorProps = {
  mblsClients: MblsConnectorClient[]
  clients: Client[]
  isLoading: boolean
  onSelectMbslClient: (client: MblsConnectorClient) => void
  onSelectClient: (client: Client) => void
  onRefetchMblsClient?: () => void
  onRefetchClient?: () => void
}

// eslint-disable-next-line
export const OsReservationClientSelector: FC<OsReservationClientSelectorProps> = ({
  mblsClients,
  clients,
  isLoading,
  onSelectMbslClient,
  onSelectClient,
  onRefetchMblsClient,
  onRefetchClient,
}) => {
  const { t } = useTranslation()

  const nbClients = clients.length + mblsClients.length

  const [isCreateClientDialogOpen, setIsCreateClientDialogOpen] =
    useState(false)

  const mode = useContext(ModeContext)

  return (
    <Stack
      spacing={1}
      direction="column"
      className={`uf-clients-search-result`}
      sx={{
        p: 2,
        // height: "50px",
        // maxHeight: "50px",
        maxHeight: "calc(100vh - 40px)",
        bgcolor: "#FAFBFB",
        width: "400px",
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          {`${t("pickClientToAutoCompleteForm")}`}
        </Typography>
        {isLoading && <CircularProgress size={"2rem"} />}
      </Stack>
      {!isLoading && (
        <Typography variant="caption">
          {`${nbClients} ${t(nbClients > 1 ? "foundClients" : "foundClient")}`}
        </Typography>
      )}
      <Stack
        direction="column"
        spacing={1}
        sx={{
          overflow: "auto",
        }}
      >
        {clients.map((c) => (
          <OsReservationClientSelectorItem
            key={c.id}
            id={c.id || "-1"}
            firstName={c.firstName || ""}
            lastName={c.lastName || ""}
            email={c.email || ""}
            phone={c.phone || ""}
            onSelectClient={() => onSelectClient(c)}
          />
        ))}
        {mblsClients.map((c) => (
          <OsReservationClientMblsSelectorItem
            key={c.id}
            client={c}
            onSelectClient={() => onSelectMbslClient(c)}
          />
        ))}
        {/* card when clients */}
        {nbClients === 0 && !isLoading && (
          <Card
            className={`uf-no-search-result`}
            sx={{
              p: 1,
              // textAlign: "center",
              minHeight: "100px",
              // vertical center
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            variant="outlined"
          >
            <Stack direction="column" spacing={1} alignItems="center">
              <Typography variant="body1" fontWeight={"bold"}>
                {t("noClientFound")}
              </Typography>
              <Typography variant="body2">
                {t("noClientFoundDescription")}
              </Typography>
            </Stack>
          </Card>
        )}
      </Stack>
      <Stack direction="column" spacing={1}>
        <Sl2Btn
          variant="outlined"
          onClick={() => setIsCreateClientDialogOpen(true)}
        >
          {t("createClient")}
        </Sl2Btn>
      </Stack>
      {mode === "embedded" && ffCreateClientEnabled && (
        <OsCreateMblsClientDialog
          open={isCreateClientDialogOpen}
          onClose={() => setIsCreateClientDialogOpen(false)}
          onSuccess={() => {
            onRefetchMblsClient && onRefetchMblsClient()
            setIsCreateClientDialogOpen(false)
          }}
        />
      )}
      {mode !== "embedded" && (
        <OsCreateSl2ClientDialog
          open={isCreateClientDialogOpen}
          onClose={() => setIsCreateClientDialogOpen(false)}
          onSuccess={() => {
            onRefetchClient && onRefetchClient()
            setIsCreateClientDialogOpen(false)
          }}
        />
      )}
    </Stack>
  )
}
