import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../baseQuerySl2"

type UpdateThingRequest = {
  body: Record<string, any>
  params?: Record<string, any>
}

type CreateThingRequest = {
  body: Record<string, any>
  params?: Record<string, any>
}
type CreateThingsResponse = {
  things: any[]
  ids: string[]
}

type DeleteThingRequest = {
  body: Record<string, any>
  params?: Record<string, any>
}

type GetThingsResponse = {
  things: any[]
  meta: {
    count: number
    nextToken: string | undefined
  }
}

export const thingsApi = createApi({
  reducerPath: "thingsApi",
  baseQuery,
  endpoints: (builder) => ({
    getThings: builder.query<
      GetThingsResponse,
      { params: Record<string, any> }
    >({
      query: ({ params }) => ({
        url: "things",
        params,
      }),
    }),
    updateThings: builder.mutation<any, UpdateThingRequest>({
      query: ({ params = {}, body }) => ({
        url: "things",
        method: "PATCH",
        params,
        body,
      }),
    }),
    createThings: builder.mutation<CreateThingsResponse, CreateThingRequest>({
      query: ({ params = {}, body }) => ({
        url: "things",
        method: "POST",
        params,
        body,
      }),
    }),
    deleteThings: builder.mutation<any, DeleteThingRequest>({
      query: ({ params = {}, body }) => ({
        url: "things",
        method: "DELETE",
        params,
        body,
      }),
    }),
  }),
})

export const {
  useGetThingsQuery,
  useLazyGetThingsQuery,
  useUpdateThingsMutation,
  useCreateThingsMutation,
  useDeleteThingsMutation,
} = thingsApi
