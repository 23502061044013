import Refresh from "@mui/icons-material/Refresh"
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { User } from "shared/user"
import { selectSaList, thingsActions } from "src/features/things/thingsSlice"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { useGetThingsQuery } from "../features/things/thingsApi"
import SaLayout from "./SaLayout"
import SaUserDrawer from "./SaUserDrawer"
import { Sl2Btn } from "./Sl2Btn"
import Sl2DataGrid from "./Sl2DataGrid"

const defaultColWidth = 200
const columns = (t: TFunction): DataGridProps["columns"] => [
  { field: "firstName", headerName: t("firstName"), width: defaultColWidth },
  { field: "lastName", headerName: t("lastName"), width: defaultColWidth },
  {
    field: "email",
    headerName: `✉️ ${t("email")}`,
    width: defaultColWidth,
    renderCell: (params) => {
      return (
        <a href={`mailto:${params.value}`} target="_blank" rel="noreferrer">
          {params.value}
        </a>
      )
    },
  },
  {
    field: "status",
    headerName: t("status"),
    minWidth: defaultColWidth,
    renderCell: (params) => {
      return (
        <Chip
          label={params.value}
          variant="outlined"
          size="small"
          color={params.value === "active" ? "primary" : "warning"}
        />
      )
    },
  },
  {
    field: "externalId",
    headerName: t("externalId"),
    minWidth: defaultColWidth,
  },
  {
    field: "isSuperAdmin",
    headerName: `🔐 ${t("isSuperAdmin")}`,
    minWidth: defaultColWidth,
  },
  {
    field: "createdAt",
    headerName: `📅 ${t("createdAt")}`,
    minWidth: defaultColWidth,
  },
  {
    field: "updatedAt",
    headerName: `📅 ${t("updatedAt")}`,
    minWidth: defaultColWidth,
  },
]

export const SaUsersPage: FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(thingsActions.clearSaList())
  }, [dispatch])

  const users = useAppSelector((state) => selectSaList<User>(state))

  const [nextToken, setNextToken] = useState<string | undefined>(undefined)

  const q = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetUsers,
      "ap.limit": 100,
      "ap.nextToken": nextToken,
    },
  })

  const [createUserDrawerOpen, setCreateUserDrawerOpen] = useState(false)

  return (
    <SaLayout selectedMenuItemLabelKey="users">
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{t("users")}</Typography>
          {!q.isLoading && (
            <IconButton onClick={q.refetch}>
              <Refresh />
            </IconButton>
          )}
          {q.isLoading && <CircularProgress />}
          <Sl2Btn
            variant="outlined"
            onClick={() => setCreateUserDrawerOpen(true)}
          >
            {t("createUser")}
          </Sl2Btn>
        </Stack>
        <Stack>
          <Box sx={{}}>
            <Sl2DataGrid
              datagridProps={{
                columns: columns(t),
                rows: users,
              }}
              isLoading={q.isLoading}
              listCount={users.length}
              nextToken={q.data?.meta?.nextToken}
              onNextTokenChange={setNextToken}
            />
          </Box>
        </Stack>
        {createUserDrawerOpen && (
          <SaUserDrawer
            open={true}
            onClose={() => setCreateUserDrawerOpen(false)}
          />
        )}
      </Stack>
    </SaLayout>
  )
}

export default SaUsersPage
