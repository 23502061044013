import { Refresh } from "@mui/icons-material"
import GridViewIcon from "@mui/icons-material/GridView"
import ViewListIcon from "@mui/icons-material/ViewList"
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { DataGridProps } from "@mui/x-data-grid"
import { TFunction } from "i18next"
import { chain } from "lodash"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Reservation } from "shared/reservation"
import { ReservationThingTypeMrn } from "shared/reservationData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import { Locker } from "../../../shared/locker"
import { LockerThingTypeMrn } from "../../../shared/lockerData"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import {
  selectSelectedReservation,
  selectThingsByThingTypeMrn,
  selectThingsByThingTypeMrnFilter,
  thingsActions,
} from "../features/things/thingsSlice"
import OsLayout from "./OsLayout"
import RectangularChip from "./RectangularChip"
import Sl2DataGrid from "./Sl2DataGrid"
import { SstReservationCard } from "./SstReservationCard"
import { UnstyledLink } from "./UnstyledLink"

const defaultPaginationModel = {
  pageSize: 100,
  page: 0,
}
const defaultColWidth = 200
type ListType = "table" | "card"

const columns = (t: TFunction, tenantId: string): DataGridProps["columns"] => [
  // reservation.sstReservation.number
  {
    field: "sstReservation.number",
    headerName: t("number"),
    width: 100,
    renderCell: (params) => {
      const reservationId = params.row?.reservation?.id as string
      const no = params.row.sstReservation.number
      if (!no) {
        return <Box>-</Box>
      }
      return (
        // <Link to={`/os/reservations/${reservationId}?tenantId=${tenantId}`}>
        //   {no}
        // </Link>
        <Typography>{no}</Typography>
      )
    },
  },
  {
    field: "sstReservation.status",
    headerName: t("status"),
    width: defaultColWidth,

    renderCell: (params) => {
      const label = params.row?.sstReservation?.status
      if (!label) {
        return <Box>-</Box>
      }
      return (
        <Box>
          <RectangularChip
            label={t(`sstReservationStatus.${label}`)}
            variant="outlined"
            size="small"
          />
        </Box>
      )
    },
  },
  {
    field: "locker",
    headerName: t("locker"),
    width: defaultColWidth,
    renderCell: (params) => {
      const l = getLockerDisplayValues(params.row?.locker as Locker)

      return (
        <Box>
          <Chip
            label={
              <Typography variant="caption">{"🗄️ " + l.i18n.name}</Typography>
            }
            variant="outlined"
          />
        </Box>
      )
    },
  },
  {
    field: "door",
    headerName: t("door"),
    width: defaultColWidth,
    renderCell: (params) => {
      const doorNumber = params.row?.door?.door_number
      // if doorNumber is a nmber, add one
      const label = doorNumber ? doorNumber + 1 : doorNumber
      if (!label) {
        return <Box>-</Box>
      }
      return (
        <Box>
          <Chip label={`${t("door")} ${label}`} variant="outlined" />
        </Box>
      )
    },
  },
  {
    field: "delivery_door",
    headerName: t("delivery_code"),
    width: defaultColWidth,
    renderCell: (params) => {
      const label = params.row?.sstReservation?.delivery_code
      if (!label) {
        return <Box>-</Box>
      }
      return (
        <Box>
          <Typography variant="caption" fontFamily={"monospace"}>
            {label}
          </Typography>
        </Box>
      )
    },
  },

  {
    field: "sstReservation.expiration_time",
    headerName: t("expirationTime"),
    width: defaultColWidth,
    renderCell: (params) => {
      const date = new Date(
        params.row?.sstReservation.expiration_time as string,
      ).toLocaleString()
      if (date === "Invalid Date") {
        return <Box>-</Box>
      }
      return <Box>{date}</Box>
    },
  },

  {
    field: "sstReservation.delivery_time",
    headerName: t("deliveryTime"),
    width: defaultColWidth,
    renderCell: (params) => {
      const date = new Date(
        params.row?.sstReservation.delivery_time as string,
      ).toLocaleString()
      if (date === "Invalid Date") {
        return <Box>-</Box>
      }
      return <Box>{date}</Box>
    },
  },

  {
    field: "sstReservation.reception_time",
    headerName: t("receptionTime"),
    width: defaultColWidth,
    renderCell: (params) => {
      const date = new Date(
        params.row?.sstReservation.reception_time as string,
      ).toLocaleString()
      if (date === "Invalid Date") {
        return <Box>-</Box>
      }
      return <Box>{date}</Box>
    },
  },
  {
    field: "createdAt",
    headerName: t("createdAt"),
    width: defaultColWidth,
    renderCell: (params) => {
      return (
        <Box>
          {new Date(
            params.row.sstReservation._created as string,
          ).toLocaleString()}
        </Box>
      )
    },
  },
]

export const OsReservationsPage: FC = () => {
  const { t } = useTranslation()
  const tenantId = useAppSelector(selectTenantId)
  const isReady = useAppSelector(selectMeIsReady)

  const lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  const { reservationId } = useParams<{
    reservationId: string | undefined
  }>()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (reservationId) {
      dispatch(thingsActions.setSelectedReservationId(reservationId))
    }
  }, [dispatch, reservationId])

  const selectedReservation = useAppSelector(selectSelectedReservation)

  const [listType, setListType] = useState<ListType>("table")

  const [nextToken, setNextToken] = useState<string | undefined>(undefined)

  const q = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetReservations,
        "ap.tenantId": tenantId,
        "ap.limit": 100,
        "ap.nextToken": nextToken,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady,
    },
  )

  const reservationFilter = useCallback(
    (thing: Reservation) => thing.tenantId === tenantId,
    [tenantId],
  )

  const reservations = useAppSelector((s) =>
    selectThingsByThingTypeMrnFilter(
      s,
      ReservationThingTypeMrn,
      reservationFilter,
    ),
  ) as unknown as Reservation[]

  const { isLoading: isLoadingLockers, refetch: refetchLockers } =
    useGetThingsQuery(
      {
        params: {
          "ap.name": SharedAccessPatternOsEnum.GetLockers,
          "ap.tenantId": tenantId,
          "ap.refreshSstLockers": true,
          tenantId,
        },
      },
      {
        skip: !tenantId,
      },
    )

  const isLoading = useMemo(
    () => q.isLoading || isLoadingLockers,
    [q.isLoading, isLoadingLockers],
  )

  const refetch = useCallback(() => {
    q.refetch()
    refetchLockers()
  }, [q, refetchLockers])

  const reservationTuples = useMemo(() => {
    // return chain(get(q, "data.things", []))
    return chain(reservations)
      .filter((t) => t.thingTypeMrn === ReservationThingTypeMrn)
      .map((reservation) => {
        if (!reservation?.sstReservation) return null
        const door = reservation?.sstReservation?.door
        const locker = lockers.find(
          (locker) => locker.sstLockerId === door?.locker_id,
        )
        return {
          id: reservation?.sstReservation?._id,
          sstReservation: reservation.sstReservation,
          locker,
          door,
          reservation,
        }
      })
      .compact()
      .value()
  }, [reservations, lockers])

  return (
    <OsLayout selectedMenuItemLabelKey="reservations">
      {isLoading && (
        <>
          <Stack direction="column" spacing={2}>
            <CircularProgress />
          </Stack>
        </>
      )}
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <UnstyledLink to={`/os/reservations?tenantId=${tenantId}`}>
              <Typography variant="h5">{`${t("reservations")} (${
                reservations.length
              })`}</Typography>
            </UnstyledLink>
            {reservationId && <Typography variant="h5">/</Typography>}

            {reservationId && (
              <Typography variant="h5" color="grey">
                {`${t("reservation")} ${
                  selectedReservation?.sstReservation?.number
                }  `}
              </Typography>
            )}
            {q.isLoading && <CircularProgress size={20} />}
            {!q.isLoading && (
              <IconButton onClick={refetch}>
                <Refresh />
              </IconButton>
            )}
          </Stack>
          {false && (
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton
                onClick={() => setListType("card")}
                disabled={listType === "card"}
              >
                <GridViewIcon />
              </IconButton>
              <IconButton
                onClick={() => setListType("table")}
                disabled={listType === "table"}
              >
                <ViewListIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <Divider />
        <Box
          sx={{
            width: "fit-content",
            minHeight: 300,
          }}
        >
          {tenantId && listType === "table" && (
            <Sl2DataGrid
              datagridProps={{
                columns: columns(t, tenantId),
                rows: reservationTuples,
                getRowId: (row) => row.reservation?.id,
              }}
              nextToken={q.data?.meta?.nextToken}
              listCount={reservations.length}
              onNextTokenChange={setNextToken}
              isLoading={q.isLoading}
            />
          )}
          {tenantId && listType === "card" && (
            <Stack direction="column" spacing={4}>
              {reservationTuples.map(
                ({ id, sstReservation, door, locker, reservation }) => (
                  <SstReservationCard
                    key={id}
                    sstReservation={sstReservation}
                    door={door}
                    locker={locker}
                    tenantId={tenantId}
                    reservation={reservation}
                  />
                ),
              )}
            </Stack>
          )}
          {tenantId && !isLoading && reservationTuples.length === 0 && (
            <Typography>{t("noReservations")}</Typography>
          )}
        </Box>
      </Stack>
    </OsLayout>
  )
}

export default OsReservationsPage
