import Refresh from "@mui/icons-material/Refresh"
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { selectTenantId, setTenantId } from "src/features/me/meSlice"
import { useGetThingsQuery } from "src/features/things/thingsApi"
import { selectSaList, thingsActions } from "src/features/things/thingsSlice"
import OsIamMembers from "./OsIamMembers"
import SaLayout from "./SaLayout"

export const SaOrgMembersPage: FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const tenantId = useAppSelector(selectTenantId)

  useEffect(() => {
    dispatch(thingsActions.clearSaList())
  }, [dispatch])

  const tenants = useAppSelector((state) => selectSaList<Tenant>(state))

  const { isLoading, refetch } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
      "ap.tenantOnly": true,
    },
  })

  return (
    <SaLayout selectedMenuItemLabelKey="orgMembers">
      <Stack direction="column" spacing={4} p={2} pl={4} mt={4} width="100%">
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <Typography variant="h5">{t("orgMembers")}</Typography>
          {!isLoading && (
            <IconButton onClick={refetch}>
              <Refresh />
            </IconButton>
          )}
          {isLoading && <CircularProgress />}
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography>{t("selectOrg")}</Typography>
          <Box>
            <Select
              value={tenantId}
              onChange={(event) => {
                dispatch(setTenantId(event.target.value as string))
              }}
              placeholder="Select an organization"
              defaultValue="-1"
              sx={{
                minWidth: 300,
                mt: 3,
              }}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id} sx={{ mb: 2 }}>
                  <Stack direction="column" spacing={1}>
                    <Typography variant="body1" fontWeight="bold">
                      {tenant.name}
                    </Typography>
                    <Typography variant="body2">{tenant.id}</Typography>
                    <Typography variant="body2">{`${t("createdAt")} ${new Date(
                      tenant.createdAt,
                    ).toLocaleString()}`}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Box>
          <OsIamMembers />

          <Box maxWidth="1000px"></Box>
        </Stack>
      </Stack>
    </SaLayout>
  )
}

export default SaOrgMembersPage
